import Slide1 from "../../../assets/images/homeSliders/bonusslider/bonus1.jpg";
import Slide2 from "../../../assets/images/homeSliders/bonusslider/bonus2.jpg";
import Slide3 from "../../../assets/images/homeSliders/bonusslider/bonus3.jpg";
const BonusData = [
  {
    a: "PLAY",
    link: "/promotion",
    image: Slide1,
    alt: "casino game",
    gameName: "Diamond Roulette",
  },
  {
    a: "PLAY",
    link: "/promotion",
    image: Slide2,
    alt: "casino game",
    gameName: "Auto Roulette",
  },
  {
    a: "PLAY",
    link: "/promotion",
    image: Slide3,
    alt: "casino game",
    gameName: "Double Ball Roulette",
  },
  {
    a: "PLAY",
    link: "/promotion",
    image: Slide1,
    alt: "casino game",
    gameName: "Diamond Roulette",
  },

  // ... more slide objects
];

export default BonusData;
