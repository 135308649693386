import Slide1 from "../../../assets/images/homeSliders/Poker/2HandCasinoHoldem.jpg";
import Slide2 from "../../../assets/images/homeSliders/Poker/BetOnTeenPatti.jpg";
import Slide3 from "../../../assets/images/homeSliders/Poker/CasinoHoldem.jpg";
import Slide4 from "../../../assets/images/homeSliders/Poker/OneDayTeenPatti.jpg";
import Slide5 from "../../../assets/images/homeSliders/Poker/OneDayTeenPattiClassic.jpg";
import Slide6 from "../../../assets/images/homeSliders/Poker/Teenpatti3card.jpg";
import Slide7 from "../../../assets/images/homeSliders/Poker/TexasHoldemBonus.jpg";
import Slide8 from "../../../assets/images/homeSliders/Poker/UltimateTexasHoldem.jpg";

const RecommendedData = [
  {
    a: "PLAY",
    link: "/casino/ezgevo-2-hand-casino-holdem",
    image: Slide1,
    alt: "casino game",
    gameName: "2 Hand Casino Holdem",
  },
  {
    a: "PLAY",
    link: "/casino/ezg-bet-on-teen-patti",
    image: Slide2,
    alt: "casino game",
    gameName: "Bet On Teen Patti",
  },
  {
    a: "PLAY",
    link: "/casino/ezg-casino-holdem",
    image: Slide3,
    alt: "casino game",
    gameName: "Casino Holdem",
  },
  {
    a: "PLAY",
    link: "/casino/ezg-one-day-teen-patti",
    image: Slide4,
    alt: "casino game",
    gameName: "One Day Teen Patti",
  },
  {
    a: "PLAY",
    link: "/casino/ezg-one-day-teen-patti",
    image: Slide5,
    alt: "casino game",
    gameName: "One Day Teen Patti Classic",
  },
  {
    a: "PLAY",
    link: "/casino/ezg-teen-patti-3-card",
    image: Slide6,
    alt: "casino game",
    gameName: "Teen Patti 3 Card",
  },
  // {
  //   a: "PLAY",
  //   link: "/casino/ezgevo-texas-holdem-bonus-poker",
  //   image: Slide7,
  //   alt: "casino game",
  //   gameName: "Texas Holdem Bonus",
  // },
  {
    a: "PLAY",
    link: "/casino/ezgevo-ultimate-texas-holdem",
    image: Slide8,
    alt: "casino game",
    gameName: "Ultimate Texas Holdem",
  },

  // ... more slide objects
];

export default RecommendedData;
