import React, { useEffect, useState } from "react";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";
import {
  Accordion,
  Button,
  Container,
  Modal,
  ProgressBar,
} from "react-bootstrap";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import BackIcon from "../../../../assets/svg/BackIcon";
import Footer from "../../../../containers/Footer";
import { AxiosApiHelper, axiosService } from "../../../../utils/axiosService";
import LeftBarSports from "../../../components/LeftBarSports";
import {
  useInvalidateSWRKeys,
  useSWRFetcher,
} from "../../../../hooks/useSWRHooks";
import moment from "moment";
import { API_URLS } from "../../../../config/apiUrls";

const BonusCard = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [modalData, setModalData] = useState({ show: false, bonusid: null });

  const handleClose = () => setModalData({ show: false, bonusid: null });
  const handleShow = (bonusid) => setModalData({ show: true, bonusid });

  const invalidateKeys = useInvalidateSWRKeys();

  const { data: bonusStats } = useSWRFetcher({
    url: params.id ? `${API_URLS.BONUS.STATS}/${params.id}` : null,
    isExternalFetch: true,
    swrConfig: {
      // onError: (err, key, config)=>console.log(err,)
      refreshInterval: 20_000, // 20 sceond refresh
    },
  });

  const { data: bonusParts, error: bonusPartsError } = useSWRFetcher({
    url: params.id ? `${API_URLS.BONUS.PARTS}/${params.id}` : null,
    isExternalFetch: true,
    swrConfig: {
      refreshInterval: 20_000, // 20 sceond refresh
    },
  });
  let status = {
    active: 1,
    pending: 2,
    claimed: 3,
    expired: 4,
    canceled: 5,
  };
  // useEffect(() => {
  //   bonusParts?.sort((a, b) => status[a.status] - status[b.status]);
  // }, [bonusParts]);
  // console.log(bonusParts);
  // console.log(bonusPartsError);

  const handleCancelBonus = async (bonusId) => {
    console.log("bonus to cancel", bonusId);
    await AxiosApiHelper({
      method: "GET",
      url: `${API_URLS.BONUS.CANCEL}/${bonusId}`,
    });

    // refetch data for these cached queries
    invalidateKeys([
      `${API_URLS.BONUS.STATS}/${params.id}`,
      `${API_URLS.BONUS.PARTS}/${params.id}`,
    ]);
    setModalData({ show: false, bonusid: null });
  };

  // const cardData = [
  //   {
  //     amount: 200,
  //     status: "Pending",
  //     reqDate: "02 Jan 2022 10:58:49 am",
  //     expDate: "02 Jan 2022 10:58:49 am",
  //     eventKey: 0,
  //     comment: "Lorem ipsum dolor sit amet, consectetur adipiscings",
  //   },
  //   {
  //     amount: 200,
  //     status: "Queued",
  //     reqDate: "02 Jan 2022 10:58:49 am",
  //     expDate: "02 Jan 2022 10:58:49 am",
  //     eventKey: 1,
  //     comment: "Lorem ipsum dolor sit amet, consectetur adipiscings",
  //   },
  //   {
  //     amount: 200,
  //     status: "Pending",
  //     reqDate: "02 Jan 2022 10:58:49 am",
  //     expDate: "02 Jan 2022 10:58:49 am",
  //     eventKey: 2,
  //     comment:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscings sdfvsdvsdvsdvsdsdv",
  //   },
  //   {
  //     amount: 200,
  //     status: "Pending",
  //     reqDate: "02 Jan 2022 10:58:49 am",
  //     expDate: "02 Jan 2022 10:58:49 am",
  //     eventKey: 3,
  //     comment:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscingsqadwetertertet",
  //   },
  //   {
  //     amount: 200,
  //     status: "Pending",
  //     reqDate: "02 Jan 2022 10:58:49 am",
  //     expDate: "02 Jan 2022 10:58:49 am",
  //     eventKey: 4,
  //     comment:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscingsefb efbrgnthjntj",
  //   },
  // ];

  // const bonusParts1 = [
  //   {
  //     id: 554,
  //     creditableAmount: 3300,
  //     status: "canceled",
  //     claimedAmount: 150,
  //     goalAmount: 10000,
  //   },
  //   {
  //     id: 335,
  //     creditableAmount: 2100,
  //     status: "active",
  //     claimedAmount: 350,
  //     goalAmount: 10000,
  //   },
  //   {
  //     id: 111,
  //     creditableAmount: 100,
  //     status: "claimed",
  //     claimedAmount: 200,
  //     goalAmount: 10000,
  //   },
  //   {
  //     id: 544,
  //     creditableAmount: 3300,
  //     status: "canceled",
  //     claimedAmount: 150,
  //     goalAmount: 10000,
  //   },
  //   {
  //     id: 222,
  //     creditableAmount: 1100,
  //     status: "pending",
  //     claimedAmount: 150,
  //     goalAmount: 10000,
  //   },
  //   {
  //     id: 444,
  //     creditableAmount: 3300,
  //     status: "canceled",
  //     claimedAmount: 150,
  //     goalAmount: 10000,
  //   },
  //   {
  //     id: 333,
  //     creditableAmount: 2100,
  //     status: "active",
  //     claimedAmount: 350,
  //     goalAmount: 10000,
  //   },
  //   {
  //     id: 333,
  //     creditableAmount: 2100,
  //     status: "expired",
  //     claimedAmount: 350,
  //     goalAmount: 10000,
  //   },
  // ];

  //            ---- Sorting cards
  // bonusParts1.sort((a, b) => status[a.status] - status[b.status]);
  //   console.log("Data Sort", bonusParts1);

  // console.log(bonusParts);

  return (
    <div>
      <HeaderAfterLogin />
      <main className="main Beforehomepage">
        <div className="d-xl-none mt--14">
          <LeftBarSports />
        </div>
        <div className="d-xl-none mt--14">
          <LeftBarSports />
        </div>
        <div className="container">
          <Container>
            <div className="pagesHeading">
              <h4>Bonus Cards</h4>
              <div className="back-link" onClick={() => navigate(-1)}>
                Back <BackIcon />
              </div>
            </div>
            <div className="primary_card mt-5 mb-5">
              {bonusStats && (
                <div className="stats-card">
                  <ul>
                    <li>
                      Bonus Name:
                      <span>{bonusStats?.bonusName}</span>
                    </li>
                    <li>
                      Bonus Code: <span>{bonusStats?.bonusCode}</span>
                    </li>
                    <li>
                      Activated:
                      <span>
                        {" "}
                        {moment(bonusStats?.createdAt).format("DD-MM-YY h:mm")}
                      </span>
                    </li>
                    <li>
                      Amount:
                      <span>
                        {bonusStats?.amount?.credited} /{" "}
                        {bonusStats?.amount?.creditable}
                      </span>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      Bonus Type:<span>{bonusStats?.bonusType}</span>{" "}
                    </li>

                    <li>
                      Parts:
                      <span>
                        {" "}
                        {bonusStats?.parts?.claimed} /{" "}
                        {bonusStats?.parts?.total}
                      </span>
                    </li>
                    <li>
                      Expires:
                      <span>
                        {moment(bonusStats?.expires).format("DD-MM-YY h:mm")}
                      </span>{" "}
                    </li>
                    <li>
                      Rollover Amount:
                      <span>
                        {bonusStats?.amount?.claimed} /{" "}
                        {bonusStats?.amount?.goalAmount}
                      </span>{" "}
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <div className="main_card">
              {Array.isArray(bonusParts) &&
                bonusParts
                  .sort((a, b) => a.partIndex - b.partIndex)
                  .sort((a, b) => status[a.status] - status[b.status])
                  .map((item, id) => (
                    <div className="cards mt-2" key={"Bonuspart" + id}>
                      <div className=" gap-5">
                        <div className="d-flex amount_data">
                          {/* {item?.partIndex} */}
                          <div>
                            Amount:
                            <span>{item?.creditableAmount}</span>{" "}
                          </div>
                          <div className="">
                            Status:
                            <span
                              className={`${
                                item?.status === "claimed"
                                  ? "status_claimed"
                                  : item?.status === "active"
                                  ? "status"
                                  : item.status === "pending"
                                  ? "staus_pending"
                                  : item.status === "expired"
                                  ? "staus_expired"
                                  : "staus_canceled"
                              }`}
                            >
                              {item?.status}
                            </span>{" "}
                          </div>
                          {item.status !== "claimed" &&
                            item.status !== "canceled" &&
                            item.status !== "expired" && (
                              <div
                                className="cancel_bonus"
                                // onClick={() => handleCancelBonus(item.id)}
                                onClick={() => handleShow(item.id)}
                              >
                                Cancel Bonus
                              </div>
                            )}
                        </div>
                        <div className="mt-3">
                          <ProgressBar
                            now={item.claimedAmount}
                            // label={item.claimedAmount}
                            max={item.goalAmount}
                            className="progressbar-section"
                          />
                          <div className="d-flex justify-content-between mt-2">
                            <span>{item.claimedAmount}</span>
                            <span>{item.goalAmount}</span>
                          </div>
                        </div>
                        {/* <div className="bonus">
                      <h6>BONUS</h6>
                      <div className="d-flex date_bonus">
                        <div className="reqDate">
                          Request Date:
                          <div className="date_new">{item.reqDate}</div>
                        </div>
                        <div className="reqDate">
                          Expire Data:
                          <div className="date_new">{item.expDate}</div>
                        </div>
                      </div>
                    </div> */}
                        {/* <div className="comments">
                      <Accordion>
                        <Accordion.Item eventKey={item.eventKey}>
                          <Accordion.Header className="">
                            Comments:
                          </Accordion.Header>
                          <Accordion.Body>{item.comment}</Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div> */}
                      </div>
                    </div>
                  ))}
            </div>
          </Container>
          <Modal
            show={modalData.show}
            onHide={handleClose}
            className="cancel_bonus_modal"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {" "}
                <h2 className="d-flex  justify-content-center">
                  Are you sure to cancel bonus ?
                </h2>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex gap-2 justify-content-center mt-3">
                {modalData.bonusid ? (
                  <Button
                    variant="success"
                    onClick={() => handleCancelBonus(modalData.bonusid)}
                  >
                    Yes
                  </Button>
                ) : (
                  ""
                )}
                <Button variant="danger" onClick={handleClose}>
                  No
                </Button>
              </div>
            </Modal.Body>
          </Modal>
        </div>
        <Footer />
      </main>
    </div>
  );
};

export default BonusCard;
