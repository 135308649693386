import { pageUrlGenerator } from "./casinoTabs.data";
// gameType

export const slotsData = {
  newgametab: {
    slide1: {
      DoubleBallRoulette: {
        redirectUrl: "/casino/ezgevo-double-ball-roulette",
        code: "1000084",
        casino: "e",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/livecasino/casino/DoubleBallRoulette.jpg",
        alt: "",
        name: "Double Ball Roulette",
        providerName: "Evolution",
        gameType: "roulette",
      },
      FirstPersonAmericanRoulette1: {
        redirectUrl: "/casino/ezgevo-first-person-american-roulette",
        code: "1000312",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/livecasino/casino/FirstPersonAmericanRoulette.jpg",
        alt: "",
        name: "First Person American Roulette",
        providerName: "Evolution",
        gameType: "roulette",
      },
      FirstPersonRoulette: {
        redirectUrl: "/casino/ezgevo-first-person-roulette",
        code: "1000118",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/livecasino/casino/FirstPersonRoulette.jpg",
        alt: "",
        name: "First Person Roulette",
        providerName: "Evolution",
        gameType: "roulette",
      },
      PrestigeAutoRoulette: {
        redirectUrl: "/casino/ezg-prestige-auto-roulette",
        code: "1000104",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/roulette/casino/PrestigeAutoROulette-Ezugi.jpg",
        alt: "",
        name: "Prestige Auto Roulette",
        providerName: "Ezugi",
        gameType: "roulette",
      },
      DiamondRoulette: {
        redirectUrl: "/casino/ezg-diamond-roulette",
        code: "221003",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/roulette/casino/DiamondRoulette-Ezugi.jpg",
        alt: "",
        name: "Diamond Roulette",
        providerName: "Ezugi",
        gameType: "roulette",
      },
      NamasteRoulette: {
        redirectUrl: "/casino/ezg-namaste-roulette",
        code: "221005",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/roulette/casino/NamasteRoulette-Ezugi.jpg",
        alt: "",
        name: "Namaste Roulette",
        providerName: "Ezugi",
        gameType: "roulette",
      },
      RussianRoulette: {
        redirectUrl: "/casino/ezg-russian-roulette",
        code: "5001",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/roulette/casino/RussianROulette-Ezugi.jpg",
        alt: "",
        name: "Russian Roulette",
        providerName: "Ezugi",
        gameType: "roulette",
      },
      TurkishRoulette: {
        redirectUrl: "/casino/ezg-turkish-roulette",
        code: "1000103",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/roulette/casino/TurkishROulette-Ezugi.jpg",
        alt: "",
        name: "Turkish Roulette",
        providerName: "Ezugi",
        gameType: "roulette",
      },
      LightningRoulette: {
        redirectUrl: "/casino/ezgevo-lightning-roulette",
        code: "1000092",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/roulette/casino/LightningRoulette-Ezugi.jpg",
        alt: "lightning roulette",
        name: "Lightning Roulette",
        providerName: "Evolution",
        gameType: "roulette",
      },
      SalonPriveRoulette: {
        redirectUrl: "/casino/ezgevo-salon-prive-roulette",
        code: "1000102",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/roulette/casino/SalonPriveRoulette-Ezugi.jpg",
        alt: "salon prive roulette",
        name: "Salon Prive Roulette",
        providerName: "Evolution",
        gameType: "roulette",
      },
      SpeedRoulette: {
        redirectUrl: "/casino/ezgevo-speed-roulette",
        code: "1000104",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/roulette/casino/SpeedRoulette-Ezugi.jpg",
        alt: "speed roulette",
        name: "Speed Roulette",
        providerName: "Evolution",
        gameType: "roulette",
      },
      // ImmersiveRoulette: {
      //   redirectUrl: "/casino/evo-immersive-roulette",
      //   code: "1000122",
      //   casino: "evolution",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/casinogame"),
      //   imgUrl: "./images/livecasino/casino/ImmersiveRoulette.jpg",
      //   alt: "",
      //   name: "Immersive Roulette",
      //   providerName: "Evolution",
      //   gameType: "roulette",
      // },
      Baccarat: {
        redirectUrl: "/casino/ezg-baccarat",
        code: "100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/baccarat/casino/Baccarat.jpg",
        alt: "",
        name: "Baccarart",
        providerName: "Ezugi",
        gameType: "baccarat",
      },
      SpeedCricketbaccarat: {
        redirectUrl: "/casino/ezg-speed-cricket-baccarat",
        code: "1000018",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/baccarat/casino/SpeedCricketBaccarat.jpg",
        alt: "",
        name: "Speed Cricket Baccarart",
        providerName: "Ezugi",
        gameType: "baccarat",
      },
      baccaratB: {
        redirectUrl: "/casino/ezgevo-baccarat-b",
        code: "120",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/baccarat/casino/BaccaratB.jpg",
        alt: "",
        name: " Baccarart B",
        providerName: "Evolution",
        gameType: "baccarat",
      },
      Super6baccarat: {
        redirectUrl: "/casino/ezg-super-6-baccarat",
        code: "130",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/baccarat/casino/Super6Baccarat.jpg",
        alt: "",
        name: "Super 6 Baccarart",
        providerName: "Ezugi",
        gameType: "baccarat",
      },
      Marinabaccarat: {
        redirectUrl: "/casino/ezg-casino-marina-baccarat-1",
        code: "32100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/baccarat/casino/MarinaBaccarat.jpg",
        alt: "",
        name: "Casino Marina Baccarart 1",
        providerName: "Ezugi",
        gameType: "baccarat",
      },
      SpeedBaccaratR: {
        redirectUrl: "/casino/ezgevo-speed-baccarat-r",
        code: "1000270",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/livecasino/casino/SpeedBaccaratR.jpg",
        alt: "",
        name: "Speed Baccarart R",
        providerName: "Evolution",
        gameType: "baccarat",
      },
      SpeedBaccaratA: {
        redirectUrl: "/casino/ezgevo-speed-baccarat-a",
        code: "1000021",
        casino: "Evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/livecasino/casino/SpeedBaccaratA.jpg",
        alt: "",
        name: "Speed Baccarart A",
        providerName: "Evolution",
        gameType: "baccarat",
      },
      SPEEDBACCARATB: {
        redirectUrl: "/casino/ezgevo-speed-baccarat-b",
        code: "1000022",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/livecasino/casino/SPEEDBACCARATB.jpg",
        alt: "",
        name: "Speed Baccarat B",
        providerName: "Evolution",
        gameType: "baccarat",
      },
      BaccaratA: {
        redirectUrl: "/casino/ezgevo-baccarat-a",
        code: "1000012",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/baccarat/casino/BaccaratA.jpg",
        alt: "",
        name: "Casino Marina Baccarart 1",
        providerName: "Evolution",
        gameType: "baccarat",
      },
      BaccaratKnockOut: {
        redirectUrl: "/casino/ezg-knockout-baccarat",
        code: "120",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/baccarat/casino/BaccaratKnockOut.jpg",
        alt: "",
        name: "Casino Marina Baccarart 1",
        providerName: "Ezugi",
        gameType: "baccarat",
      },
      BaccaratSqueeze: {
        redirectUrl: "/casino/ezgevo-baccarat-squeeze",
        code: "1000016",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/baccarat/casino/BaccaratSqueeze.jpg",
        alt: "",
        name: "Casino Marina Baccarart 1",
        providerName: "Evolution",
        gameType: "baccarat",
      },
      CasinoMarinaBaccarat2: {
        redirectUrl: "/casino/ezg-casino-marina-baccarat-2",
        code: "32101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/baccarat/casino/CasinoMarinaBaccarat2.jpg",
        alt: "",
        name: "Casino Marina Baccarart 1",
        providerName: "Ezugi",
        gameType: "baccarat",
      },
      CricketWar: {
        redirectUrl: "/casino/ezg-cricket-war",
        code: "45100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/baccarat/casino/CricketWar.jpg",
        alt: "",
        name: "Casino Marina Baccarart 1",
        providerName: "Ezugi",
        gameType: "baccarat",
      },
      DragonTiger: {
        redirectUrl: "/casino/ezg-dragon-tiger",
        code: "150",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/baccarat/casino/DragonTiger.jpg",
        alt: "",
        name: "Casino Marina Baccarart 1",
        providerName: "Ezugi",
        gameType: "baccarat",
      },
      NoCommissionBaccarat: {
        redirectUrl: "/casino/ezg-no-commission-baccarat",
        code: "170",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/baccarat/casino/NoCommissionBaccarat.jpg",
        alt: "",
        name: "Casino Marina Baccarart 1",
        providerName: "Ezugi",
        gameType: "baccarat",
      },
      BlackjackB: {
        redirectUrl: "/casino/ezgevo-blackjack-b",
        code: "201",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/blackjack/casino/BLackJackB.jpg",
        alt: "",
        name: "Blackjack B",
        providerName: "Evolution",
        gameType: "blackjack",
      },
      BlackjackParty: {
        redirectUrl: "/casino/ezgevo-blackjack-party",
        code: "224",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/blackjack/casino/BlackjackParty.jpg",
        alt: "",
        name: "Blackjack Party",
        providerName: "Evolution",
        gameType: "blackjack",
      },
      BlackjackC: {
        redirectUrl: "/casino/ezgevo-blackjack-c",
        code: "225",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/blackjack/casino/BlackJackC.jpg",
        alt: "",
        name: " Blackjack C",
        providerName: "Evolution",
        gameType: "blackjack",
      },
      BlackjackSilverA: {
        redirectUrl: "/casino/ezgevo-blackjack-silver-a",
        code: "226",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/blackjack/casino/BlackJackSilver.jpg",
        alt: "",
        name: " Blackjack Silver A",
        providerName: "Evolution",
        gameType: "blackjack",
      },
      BlackjackVIP1: {
        redirectUrl: "/casino/ezgevo-blackjack-vip-1",
        code: "501",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/blackjack/casino/BlackjackVIP1.jpg",
        alt: "",
        name: "Blackjack VIP 1",
        providerName: "Evolution",
        gameType: "blackjack",
      },
      BlackjackSilverF: {
        redirectUrl: "/casino/ezgevo-blackjack-silver-f",
        code: "1000048",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/blackjack/casino/BlackjackSilverF.jpg",
        alt: "",
        name: "Blackjack Silver F",
        providerName: "Evolution",
        gameType: "blackjack",
      },
      Blackjack1: {
        redirectUrl: "/casino/ezg-blackjack-1",
        code: "201",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/blackjack/casino/Blackjack1.jpg",
        alt: "",
        name: "Blackjack 1",
        providerName: "Ezugi",
        gameType: "blackjack",
      },
      BlackjackGold4: {
        redirectUrl: "/casino/ez-blackjack-gold-4",
        code: "4",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/blackjack/casino/BlackjackGold4.jpg",
        alt: "",
        name: "Blackjack Gold 4",
        providerName: "Ezugi",
        gameType: "blackjack",
      },
      BlackJackGold5: {
        redirectUrl: "/casino/ez-blackjack-gold-5",
        code: "1",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/blackjack/casino/BlackJackGold5.jpg",
        alt: "",
        name: "Blackjack Gold 5",
        providerName: "Ezugi",
        gameType: "blackjack",
      },
      BlackjackPlatinum1: {
        redirectUrl: "/casino/ez-blackjack-platinum-1",
        code: "5",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/blackjack/casino/BlackjackPlatinum1.jpg",
        alt: "",
        name: "Blackjack Platinum 1",
        providerName: "Ezugi",
        gameType: "blackjack",
      },
      RumbaBlackjack1: {
        redirectUrl: "/casino/ezg-rumba-blackjack-1",
        code: "411",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/blackjack/casino/RumbaBlackjack1.jpg",
        alt: "",
        name: "Rumba Blackjack 1",
        providerName: "Ezugi",
        gameType: "blackjack",
      },
      Sicbo: {
        redirectUrl: "/casino/ezg-sic-bo",
        code: "224000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/sicbo/casino/Sicbo.jpg",
        alt: "sicbo",
        name: "Sicbo",
        providerName: "Ezugi",
        gameType: "sicbo",
      },
      UltimateSicBo: {
        redirectUrl: "/casino/ezg-ultimate-sic-bo",
        code: "224100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/sicbo/casino/UltimateSicBo.jpg",
        alt: "ultimate sicbo",
        name: "Ultimate Sicbo",
        providerName: "Ezugi",
        gameType: "sicbo",
      },
      SideBetCity: {
        redirectUrl: "/casino/ezgevo-side-bet-city",
        code: "1000115",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/livecasino/casino/SideBetCity.jpg",
        alt: "",
        name: "Side Bet City",
        providerName: "Evolution",
        gameType: "baccarat",
      },
      HandCasinoHoldem2: {
        redirectUrl: "/casino/ezgevo-2-hand-casino-holdem",
        code: "1000073",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/poker/casino/2HandCasinoHoldem.jpg",
        alt: "",
        name: "2 Hand Casino Holdem",
        providerName: "Evolution",
        gameType: "poker",
      },
      BetOnTeenPatti: {
        redirectUrl: "/casino/ezg-bet-on-teen-patti",
        code: "227101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/poker/casino/BetOnTeenPatti.jpg",
        alt: "",
        name: "Bet On Teen Patti",
        providerName: "Ezugi",
        gameType: "poker",
      },
      CasinoHoldem: {
        redirectUrl: "/casino/ezg-casino-holdem",
        code: "507000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/poker/casino/CasinoHoldem.jpg",
        alt: "",
        name: "Casino Holdem",
        providerName: "Ezugi",
        gameType: "poker",
      },
      OneDayTeenPattiClassic: {
        redirectUrl: "/casino/ezg-one-day-teen-patti",
        code: "227103",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/poker/casino/OneDayTeenPattiClassic.jpg",
        alt: "",
        name: "One Day Teen Patti",
        providerName: "Ezugi",
        gameType: "poker",
      },
      TeenPatti3card: {
        redirectUrl: "/casino/ezg-teen-patti-3-card",
        code: "227100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/livecasino/casino/TeenPatti3card.jpg",
        alt: "",
        name: " Teen Patti 3 Card",
        providerName: "Ezugi",
        gameType: "poker",
      },
      TexasHoldembonus: {
        redirectUrl: "/casino/ezgevo-texas-holdem-bonus-poker",
        code: "1000111",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/livecasino/casino/Texasholdem.jpg",
        alt: "",
        name: "Texas Holdem Bonus",
        providerName: "Evolution",
        gameType: "poker",
      },
      Ultimatetexasholdem: {
        redirectUrl: "/casino/ezgevo-ultimate-texas-holdem",
        code: "1000151",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/livecasino/casino/Ultimatetexasholdem.jpg",
        alt: "",
        name: "Ultimate Texas Holdem",
        providerName: "Evolution",
        gameType: "poker",
      },
    },

    imgUrl: "./images/icons/casino/roulette.png",
    name: "Live Casino",
  },

  populargametab: {
    slide1: {
      BlackjackB: {
        redirectUrl: "/casino/ezgevo-blackjack-b",
        code: "201",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/blackjack/casino/BLackJackB.jpg",
        alt: "",
        name: "Blackjack B",
        providerName: "Evolution",
        gameType: "blackjack",
      },
      BlackjackParty: {
        redirectUrl: "/casino/ezgevo-blackjack-party",
        code: "224",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/blackjack/casino/BlackjackParty.jpg",
        alt: "",
        name: "Blackjack Party",
        providerName: "Evolution",
        gameType: "blackjack",
      },
      BlackjackC: {
        redirectUrl: "/casino/ezgevo-blackjack-c",
        code: "225",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/blackjack/casino/BlackJackC.jpg",
        alt: "",
        name: " Blackjack C",
        providerName: "Evolution",
        gameType: "blackjack",
      },
      BlackjackSilverA: {
        redirectUrl: "/casino/ezgevo-blackjack-silver-a",
        code: "226",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/blackjack/casino/BlackJackSilver.jpg",
        alt: "",
        name: " Blackjack Silver A",
        providerName: "Evolution",
        gameType: "blackjack",
      },
      BlackjackVIP1: {
        redirectUrl: "/casino/ezgevo-blackjack-vip-1",
        code: "501",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/blackjack/casino/BlackjackVIP1.jpg",
        alt: "",
        name: "Blackjack VIP 1",
        providerName: "Evolution",
        gameType: "blackjack",
      },
      BlackjackSilverF: {
        redirectUrl: "/casino/ezgevo-blackjack-silver-f",
        code: "1000048",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/blackjack/casino/BlackjackSilverF.jpg",
        alt: "",
        name: "Blackjack Silver F",
        providerName: "Evolution",
        gameType: "blackjack",
      },
      Blackjack1: {
        redirectUrl: "/casino/ezg-blackjack-1",
        code: "201",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/blackjack/casino/Blackjack1.jpg",
        alt: "",
        name: "Blackjack 1",
        providerName: "Ezugi",
        gameType: "blackjack",
      },
      BlackjackGold4: {
        redirectUrl: "/casino/ez-blackjack-gold-4",
        code: "4",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/blackjack/casino/BlackjackGold4.jpg",
        alt: "",
        name: "Blackjack Gold 4",
        providerName: "Ezugi",
        gameType: "blackjack",
      },
      BlackJackGold5: {
        redirectUrl: "/casino/ez-blackjack-gold-5",
        code: "1",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/blackjack/casino/BlackJackGold5.jpg",
        alt: "",
        name: "Blackjack Gold 5",
        providerName: "Ezugi",
        gameType: "blackjack",
      },
      BlackjackPlatinum1: {
        redirectUrl: "/casino/ez-blackjack-platinum-1",
        code: "5",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/blackjack/casino/BlackjackPlatinum1.jpg",
        alt: "",
        name: "Blackjack Platinum 1",
        providerName: "Ezugi",
        gameType: "blackjack",
      },
      RumbaBlackjack1: {
        redirectUrl: "/casino/ezg-rumba-blackjack-1",
        code: "411",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/blackjack/casino/RumbaBlackjack1.jpg",
        alt: "",
        name: "Rumba Blackjack 1",
        providerName: "Ezugi",
        gameType: "blackjack",
      },
      DoubleBallRoulette: {
        redirectUrl: "/casino/ezgevo-double-ball-roulette",
        code: "1000084",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/livecasino/casino/DoubleBallRoulette.jpg",
        alt: "",
        name: "Double Ball Roulette",
        providerName: "Evolution",
        gameType: "roulette",
      },
      FirstPersonAmericanRoulette1: {
        redirectUrl: "/casino/ezgevo-first-person-american-roulette",
        code: "1000312",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/livecasino/casino/FirstPersonAmericanRoulette.jpg",
        alt: "",
        name: "First Person American Roulette",
        providerName: "Evolution",
        gameType: "roulette",
      },
      FirstPersonRoulette: {
        redirectUrl: "/casino/ezgevo-first-person-roulette",
        code: "1000118",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/roulette/casino/FirstPersonRoulette-Ezugi.jpg",
        alt: "first person roulette",
        name: "First Person Roulette",
        providerName: "Evolution",
        gameType: "roulette",
      },
      PrestigeAutoRoulette: {
        redirectUrl: "/casino/ezg-prestige-auto-roulette",
        code: "1000104",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/roulette/casino/PrestigeAutoROulette-Ezugi.jpg",
        alt: "",
        name: "Prestige Auto Roulette",
        providerName: "Ezugi",
        gameType: "roulette",
      },
      DiamondRoulette: {
        redirectUrl: "/casino/ezg-diamond-roulette",
        code: "221003",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/roulette/casino/DiamondRoulette-Ezugi.jpg",
        alt: "",
        name: "Diamond Roulette",
        providerName: "Ezugi",
        gameType: "roulette",
      },
      NamasteRoulette: {
        redirectUrl: "/casino/ezg-namaste-roulette",
        code: "221005",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/roulette/casino/NamasteRoulette-Ezugi.jpg",
        alt: "",
        name: "Namaste Roulette",
        providerName: "Ezugi",
        gameType: "roulette",
      },
      RussianRoulette: {
        redirectUrl: "/casino/ezg-russian-roulette",
        code: "5001",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/roulette/casino/RussianROulette-Ezugi.jpg",
        alt: "",
        name: "Russian Roulette",
        providerName: "Ezugi",
        gameType: "roulette",
      },
      TurkishRoulette: {
        redirectUrl: "/casino/ezg-turkish-roulette",
        code: "1000103",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/roulette/casino/TurkishROulette-Ezugi.jpg",
        alt: "",
        name: "Turkish Roulette",
        providerName: "Ezugi",
        gameType: "roulette",
      },
      LightningRoulette: {
        redirectUrl: "/casino/ezgevo-lightning-roulette",
        code: "1000092",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/roulette/casino/LightningRoulette-Ezugi.jpg",
        alt: "lightning roulette",
        name: "Lightning Roulette",
        providerName: "Evolution",
        gameType: "roulette",
      },
      SalonPriveRoulette: {
        redirectUrl: "/casino/ezgevo-salon-prive-roulette",
        code: "1000102",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/roulette/casino/SalonPriveRoulette-Ezugi.jpg",
        alt: "salon prive roulette",
        name: "Salon Prive Roulette",
        providerName: "Evolution",
        gameType: "roulette",
      },
      SpeedRoulette: {
        redirectUrl: "/casino/ezgevo-speed-roulette",
        code: "1000104",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/roulette/casino/SpeedRoulette-Ezugi.jpg",
        alt: "speed roulette",
        name: "Speed Roulette",
        providerName: "Evolution",
        gameType: "roulette",
      },
      Baccarat: {
        redirectUrl: "/casino/ezg-baccarat",
        code: "100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/baccarat/casino/Baccarat.jpg",
        alt: "",
        name: "Baccarart",
        providerName: "Ezugi",
        gameType: "baccarat",
      },
      SpeedCricketbaccarat: {
        redirectUrl: "/casino/ezg-speed-cricket-baccarat",
        code: "1000018",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/baccarat/casino/SpeedCricketBaccarat.jpg",
        alt: "",
        name: "Speed Cricket Baccarart",
        providerName: "Ezugi",
        gameType: "baccarat",
      },
      baccaratB: {
        redirectUrl: "/casino/ezgevo-baccarat-b",
        code: "120",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/baccarat/casino/BaccaratB.jpg",
        alt: "",
        name: " Baccarart B",
        providerName: "Evolution",
        gameType: "baccarat",
      },
      Super6baccarat: {
        redirectUrl: "/casino/ezg-super-6-baccarat",
        code: "130",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/baccarat/casino/Super6Baccarat.jpg",
        alt: "",
        name: "Super 6 Baccarart",
        providerName: "Ezugi",
        gameType: "baccarat",
      },
      Marinabaccarat: {
        redirectUrl: "/casino/ezg-casino-marina-baccarat-1",
        code: "32100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/baccarat/casino/MarinaBaccarat.jpg",
        alt: "",
        name: "Casino Marina Baccarart 1",
        providerName: "Ezugi",
        gameType: "baccarat",
      },
      SpeedBaccaratR: {
        redirectUrl: "/casino/ezgevo-speed-baccarat-r",
        code: "1000270",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/livecasino/casino/SpeedBaccaratR.jpg",
        alt: "",
        name: "Speed Baccarart R",
        providerName: "Evolution",
        gameType: "baccarat",
      },
      SpeedBaccaratA: {
        redirectUrl: "/casino/ezgevo-speed-baccarat-a",
        code: "1000021",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/livecasino/casino/SpeedBaccaratA.jpg",
        alt: "",
        name: "Speed Baccarart A",
        providerName: "Evolution",
        gameType: "baccarat",
      },
      SPEEDBACCARATB: {
        redirectUrl: "/casino/ezgevo-speed-baccarat-b",
        code: "1000022",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/livecasino/casino/SPEEDBACCARATB.jpg",
        alt: "",
        name: "Speed Baccarat B",
        providerName: "Evolution",
        gameType: "baccarat",
      },
      BaccaratA: {
        redirectUrl: "/casino/ezgevo-baccarat-a",
        code: "1000012",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/baccarat/casino/BaccaratA.jpg",
        alt: "",
        name: "Casino Marina Baccarart 1",
        providerName: "Evolution",
        gameType: "baccarat",
      },
      BaccaratKnockOut: {
        redirectUrl: "/casino/ezg-knockout-baccarat",
        code: "120",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/baccarat/casino/BaccaratKnockOut.jpg",
        alt: "",
        name: "Casino Marina Baccarart 1",
        providerName: "Ezugi",
        gameType: "baccarat",
      },
      BaccaratSqueeze: {
        redirectUrl: "/casino/ezgevo-baccarat-squeeze",
        code: "1000016",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/baccarat/casino/BaccaratSqueeze.jpg",
        alt: "",
        name: "Casino Marina Baccarart 1",
        providerName: "Evolution",
        gameType: "baccarat",
      },
      CasinoMarinaBaccarat2: {
        redirectUrl: "/casino/ezg-casino-marina-baccarat-2",
        code: "32101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/baccarat/casino/CasinoMarinaBaccarat2.jpg",
        alt: "",
        name: "Casino Marina Baccarart 1",
        providerName: "Ezugi",
        gameType: "baccarat",
      },
      CricketWar: {
        redirectUrl: "/casino/ezg-cricket-war",
        code: "45100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/baccarat/casino/CricketWar.jpg",
        alt: "",
        name: "Casino Marina Baccarart 1",
        providerName: "Ezugi",
        gameType: "baccarat",
      },
      DragonTiger: {
        redirectUrl: "/casino/ezg-dragon-tiger",
        code: "150",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/baccarat/casino/DragonTiger.jpg",
        alt: "",
        name: "Casino Marina Baccarart 1",
        providerName: "Ezugi",
        gameType: "baccarat",
      },
      NoCommissionBaccarat: {
        redirectUrl: "/casino/ezg-no-commission-baccarat",
        code: "170",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/baccarat/casino/NoCommissionBaccarat.jpg",
        alt: "",
        name: "Casino Marina Baccarart 1",
        providerName: "Ezugi",
        gameType: "baccarat",
      },
      Sicbo: {
        redirectUrl: "/casino/ezg-sic-bo",
        code: "224000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/sicbo/casino/Sicbo.jpg",
        alt: "sicbo",
        name: "Sicbo",
        providerName: "Ezugi",
        gameType: "sicbo",
      },
      UltimateSicBo: {
        redirectUrl: "/casino/ezg-ultimate-sic-bo",
        code: "224100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/sicbo/casino/UltimateSicBo.jpg",
        alt: "ultimate sicbo",
        name: "Ultimate Sicbo",
        providerName: "Ezugi",
        gameType: "sicbo",
      },
      Hand2CasinoHoldem: {
        redirectUrl: "/casino/ezgevo-2-hand-casino-holdem",
        code: "1000073",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/poker/casino/2HandCasinoHoldem.png",
        alt: "",
        name: " 2 Hand Casino Holdem",
        providerName: "Evolution",
        gameType: "poker",
      },
      BetonTeenPatti: {
        redirectUrl: "/casino/ezg-bet-on-teen-patti",
        code: "227101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/poker/casino/BetOnTeenPatti.png",
        alt: "",
        name: "Bet on Teen Patti",
        providerName: "Ezugi",
        gameType: "poker",
      },
      Onedayteenpatti1: {
        redirectUrl: "/casino/ezg-one-day-teen-patti",
        code: "227103",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/poker/casino/OneDayTeenPAtti.jpg",
        alt: "",
        name: "One Day Teen Patti",
        providerName: "Ezugi",
        gameType: "poker",
      },
      CasinoHoldem: {
        redirectUrl: "/casino/ezg-casino-holdem",
        code: "507000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/poker/casino/CasinoHoldem.jpg",
        alt: "",
        name: "Casino Holdem",
        providerName: "Ezugi",
        gameType: "poker",
      },
      OneDayTeenPattiClassic: {
        redirectUrl: "/casino/ezg-one-day-teen-patti",
        code: "227103",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/poker/casino/OneDayTeenPattiClassic.jpg",
        alt: "",
        name: "One Day Teen Patti",
        providerName: "Ezugi",
        gameType: "poker",
      },
      Teenpatti3card: {
        redirectUrl: "/casino/ezg-teen-patti-3-card",
        code: "227100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/poker/casino/Teenpatti3card.jpg",
        alt: "",
        name: "Teen Patti 3 Card",
        providerName: "Ezugi",
        gameType: "poker",
      },
      TexasHoldemBonus: {
        redirectUrl: "/casino/ezgevo-texas-holdem-bonus-poker",
        code: "1000111",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/poker/casino/TexasHoldemBonus.jpg",
        alt: "",
        name: "Texas Holdem Bonus",
        providerName: "Evolution",
        gameType: "poker",
      },
      UltimateTexasHoldem: {
        redirectUrl: "/casino/ezgevo-ultimate-texas-holdem",
        code: "1000151",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/poker/casino/UltimateTexasHoldem.jpg",
        alt: "",
        name: "Ultimate Texas Holdem",
        providerName: "Evolution",
        gameType: "poker",
      },
    },

    imgUrl: "./images/icons/casino/CasinoGames.png",
    name: "Casino Games",
  },

  topProvidertabs: {
    slide1: {
      EzugiProvider1: {
        redirectUrl: "/casino/ezugi",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/providers/new/Ezugi.png",
        alt: "",
        name: "Ezugi",
        providerName: "Ezugi",
        gameType: "ezugi",
      },
      EvolutionProvider1: {
        redirectUrl: "/casino/evolution",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/providers/new/Evolution.png",
        alt: "",
        name: "Evolution",
        providerName: "Evolution",
        gameType: "evolution",
      },
      VivoGamingProvider1: {
        redirectUrl: "/casino/vivo",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/providers/new/VivoGaming.png",
        alt: "",
        name: "VivoGaming",
        providerName: "VivoGaming",
        gameType: "vivoGaming",
      },
      SupernowaProvider1: {
        redirectUrl: "/casino/supernowa",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/providers/new/Supernowa.png",
        alt: "",
        name: "Supernowa",
        providerName: "Supernowa",
        gameType: "supernowa",
      },
      XPGProvider1: {
        redirectUrl: "/casino/xpg",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/providers/new/XPG.png",
        alt: "",
        name: "XPG",
        providerName: "XPG",
        gameType: "xpg",
      },
      OnetouchProvider1: {
        redirectUrl: "/casino/onetouch",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/providers/new/OneTouch.png",
        alt: "",
        name: "Onetouch",
        providerName: "Onetouch",
        gameType: "onetouch",
      },
      ParagmaticProvider1: {
        redirectUrl: "/casino/pragmaticplay",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/providers/new/Paragmatic.png",
        alt: "",
        name: "Paragmatic",
        providerName: "Paragmatic",
        gameType: "paragmatic",
      },
      qtechProvider1: {
        redirectUrl: "/casino/qtech",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/providers/new/Qtech.png",
        alt: "",
        name: "Qtech",
        providerName: "Qtech",
        gameType: "qtech",
      },
    },

    imgUrl: "./images/icons/casino/provider.png",
    name: "Top Providers",
    class: "topProviders",
  },

  netenttab: {
    slide1: {
      asgardianstones: {
        redirectUrl: "/casino/ezugi/asgardianstones",
        code: "1100096",
        casino: "ezugi",
        imgUrl: "./images/slots/casino/asgardianstones.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Asgardian Stones",
        providerName: "NetEnt",
        gameType: "netent",
      },
      secrets: {
        redirectUrl: "/casino/ezugi/secrets",
        code: "1100106",
        casino: "ezugi",
        imgUrl: "./images/slots/casino/SecretsofAtlantis.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Secrets of Atlantis",
        providerName: "NetEnt",
        gameType: "netent",
      },
      SteamTower: {
        redirectUrl: "/casino/ezugi/SteamTower",
        code: "1100043",
        casino: "ezugi",
        imgUrl: "./images/slots/casino/SteamTower.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Steam Tower",
        providerName: "NetEnt",
        gameType: "netent",
      },
      DivinieFortuneMegaways: {
        redirectUrl: "/casino/ezugi/DivinieFortuneMegaways",
        code: "1100089",
        casino: "ezugi",
        imgUrl: "./images/slots/casino/DivinieFortuneMegaways.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Divine Fortune Megaways",
        providerName: "NetEnt",
        gameType: "netent",
      },
      FruitShopMegaways: {
        redirectUrl: "/casino/ezugi/FruitShopMegaways",
        code: "1100094",
        casino: "ezugi",
        imgUrl: "./images/slots/casino/FruitShopMegaways.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Fruit Shop Megaways",
        providerName: "NetEnt",
        gameType: "netent",
      },
      GorillaKingdom: {
        redirectUrl: "/casino/ezugi/GorillaKingdom",
        code: "1100088",
        casino: "ezugi",
        imgUrl: "./images/slots/casino/GorillaKingdom.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Gorilla Kingdom",
        providerName: "NetEnt",
        gameType: "netent",
      },
      ReelKingMegaways: {
        redirectUrl: "/casino/ezugi/ReelKingMegaways",
        code: "1100052",
        casino: "ezugi",
        imgUrl: "./images/slots/casino/ReelKingMegaways.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Reel King Mega",
        providerName: "NetEnt",
        gameType: "redtiger",
      },
      Christmas: {
        redirectUrl: "/casino/ezugi/Christmas",
        code: "1100032",
        casino: "ezugi",
        imgUrl: "./images/slots/casino/Christmas.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Aloha Christmas",
        providerName: "NetEnt",
        gameType: "netent",
      },
      ClusterPays: {
        redirectUrl: "/casino/ezugi/ClusterPays",
        code: "1100033",
        casino: "ezugi",
        imgUrl: "./images/slots/casino/AlohaClusterPays.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Aloha Cluster Pays",
        providerName: "NetEnt",
        gameType: "netent",
      },
      WildWest: {
        redirectUrl: "/casino/ezugi/WildWest",
        code: "1100038",
        casino: "ezugi",
        imgUrl: "./images/slots/casino/WildWestGold.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Wild West The Great Train Heist",
        providerName: "NetEnt",
        gameType: "netent",
      },
      NinjaWays: {
        redirectUrl: "/casino/ezugi/NinjaWays",
        code: "1100166",
        casino: "ezugi",
        imgUrl: "./images/slots/casino/NinjaWays.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Ninja Ways",
        providerName: "Red Tiger",
        gameType: "redtiger",
      },
      PathofDestiny: {
        redirectUrl: "/casino/ezugi/PathofDestiny",
        code: "1100167",
        casino: "ezugi",
        imgUrl: "./images/slots/casino/PathofDestiny.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Path of Destiny",
        providerName: "Red Tiger",
        gameType: "redtiger",
      },
      PersianFortune: {
        redirectUrl: "/casino/ezugi/PersianFortune",
        code: "1100168",
        casino: "ezugi",
        imgUrl: "./images/slots/casino/PersianFortune.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Persian Fortune",
        providerName: "Red Tiger",
        gameType: "redtiger",
      },
      ReelHeist: {
        redirectUrl: "/casino/ezugi/ReelHeist",
        code: "1100169",
        casino: "ezugi",
        imgUrl: "./images/slots/casino/ReelHeist.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Reel Heist",
        providerName: "Red Tiger",
        gameType: "redtiger",
      },
      RobinHoodWildForest: {
        redirectUrl: "/casino/ezugi/RobinHoodWildForest",
        code: "1100170",
        casino: "ezugi",
        imgUrl: "./images/slots/casino/RobinHoodWildForest.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Robin Hood`s Wild Forest",
        providerName: "Red Tiger",
        gameType: "redtiger",
      },
      SnowWild: {
        redirectUrl: "/casino/ezugi/SnowWild",
        code: "1100171",
        casino: "ezugi",
        imgUrl: "./images/slots/casino/SnowWild.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Snow Wild And The 7 Features",
        providerName: "Red Tiger",
        gameType: "redtiger",
      },
      SylvanSpirits: {
        redirectUrl: "/casino/ezugi/SylvanSpirits",
        code: "1100172",
        casino: "ezugi",
        imgUrl: "./images/slots/casino/SylvanSpirits.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Sylvan Spirits",
        providerName: "Red Tiger",
        gameType: "redtiger",
      },
      TheGreatestTrainRobbery: {
        redirectUrl: "/casino/ezugi/TheGreatestTrainRobbery",
        code: "1100173",
        casino: "ezugi",
        imgUrl: "./images/slots/casino/TheGreatestTrainRobbery.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "The Greatest Train Robbery",
        providerName: "Red Tiger",
        gameType: "redtiger",
      },
      TheWildHatter: {
        redirectUrl: "/casino/ezugi/TheWildHatter",
        code: "1100174",
        casino: "ezugi",
        imgUrl: "./images/slots/casino/TheWildHatter.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "The Wild Hatter",
        providerName: "Red Tiger",
        gameType: "redtiger",
      },
      ThorLightning: {
        redirectUrl: "/casino/ezugi/ThorLightning",
        code: "1100175",
        casino: "ezugi",
        imgUrl: "./images/slots/casino/ThorLightning.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Thor`s Lightning",
        providerName: "Red Tiger",
        gameType: "redtiger",
      },
      DragonLuck: {
        redirectUrl: "/casino/ezugi/DragonLuck",
        code: "1100001",
        casino: "ezugi",
        imgUrl: "./images/slots/casino/DragonLuck.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Dragon's Luck",
        providerName: "Red Tiger",
        gameType: "redtiger",
      },
      DragonLuckReel: {
        redirectUrl: "/casino/ezugi/DragonLuckReel",
        code: "1100002",
        casino: "ezugi",
        imgUrl: "./images/slots/casino/DragonLuckReel.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Dragon's Luck Power Reels",
        providerName: "Red Tiger",
        gameType: "redtiger",
      },
    },

    slide2: {
      TikiFruits: {
        redirectUrl: "/casino/ezugi/TikiFruits",
        code: "1100176",
        casino: "ezugi",
        imgUrl: "./images/slots/casino/TikiFruits.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Tiki Fruits",
        providerName: "Red Tiger",
        gameType: "redtiger",
      },
      TotemLightning: {
        redirectUrl: "/casino/ezugi/TotemLightning",
        code: "1100177",
        casino: "ezugi",
        imgUrl: "./images/slots/casino/TotemLightning.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Totem Lightning",
        providerName: "Red Tiger",
        gameType: "redtiger",
      },
      VaultCracker: {
        redirectUrl: "/casino/ezugi/VaultCracker",
        code: "1100178",
        casino: "ezugi",
        imgUrl: "./images/slots/casino/VaultCracker.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Vault Cracker",
        providerName: "Red Tiger",
        gameType: "redtiger",
      },
      VaultofAnubis: {
        redirectUrl: "/casino/ezugi/VaultofAnubis",
        code: "1100179",
        casino: "ezugi",
        imgUrl: "./images/slots/casino/VaultofAnubis.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Vault of Anubis",
        providerName: "Red Tiger",
        gameType: "redtiger",
      },
      WellofWishes: {
        redirectUrl: "/casino/ezugi/WellofWishes",
        code: "1100180",
        casino: "ezugi",
        imgUrl: "./images/slots/casino/Well-of-Wishes.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Well of Wishes",
        providerName: "Red Tiger",
        gameType: "redtiger",
      },
      WildCatsMultiline: {
        redirectUrl: "/casino/ezugi/WildCatsMultiline",
        code: "1100181",
        casino: "ezugi",
        imgUrl: "./images/slots/casino/WildCatsMultiline.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Wild Cats Multiline",
        providerName: "Red Tiger",
        gameType: "redtiger",
      },
      WildOClock: {
        redirectUrl: "/casino/ezugi/WildOClock",
        code: "1100182",
        casino: "ezugi",
        imgUrl: "./images/slots/casino/WildOClock.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Wild O`Clock",
        providerName: "Red Tiger",
        gameType: "redtiger",
      },
      WildChest: {
        redirectUrl: "/casino/ezugi/WildChest",
        code: "1100183",
        casino: "ezugi",
        imgUrl: "./images/slots/casino/Wildchest.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Wild Wild Chest",
        providerName: "Red Tiger",
        gameType: "redtiger",
      },
      WinEscalator: {
        redirectUrl: "/casino/ezugi/WinEscalator",
        code: "1100184",
        casino: "ezugi",
        imgUrl: "./images/slots/casino/WinEscalator.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Win Escalator",
        providerName: "Red Tiger",
        gameType: "redtiger",
      },
      YucatanMystery: {
        redirectUrl: "/casino/ezugi/YucatanMystery",
        code: "1100185",
        casino: "ezugi",
        imgUrl: "./images/slots/casino/YucatanMystery.jpg",
        homeUrl: pageUrlGenerator("/Slots"),
        name: "Yucatan`s Mystery",
        providerName: "Red Tiger",
        gameType: "redtiger",
      },
    },

    imgUrl: "./images/icons/casino/slots.png",
    name: "Slots",
  },

  VirtualTabs: {
    slide1: {
      Cricket1: {
        redirectUrl: "/betby",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/VirtualSlider/Cricket.jpg",
        alt: "",
        name: "Cricket",
        providerName: "Cricket",
        gameType: "",
      },
      Soccer: {
        redirectUrl: "/betby",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/VirtualSlider/Soccer.jpg",
        alt: "",
        name: "Soccer",
        providerName: "Soccer",
        gameType: "",
      },
      Tennis: {
        redirectUrl: "/casino/vivo",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/VirtualSlider/Tennis.jpg",
        alt: "",
        name: "Tennis",
        providerName: "Tennis",
        gameType: "",
      },
      Inplay: {
        redirectUrl: "/betby",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/VirtualSlider/In-Play.jpg",
        alt: "",
        name: "Inplay",
        providerName: "Inplay",
        gameType: "",
      },
      basketball: {
        redirectUrl: "/betby",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/VirtualSlider/basketball.jpg",
        alt: "",
        name: "basketball",
        providerName: "basketball",
        gameType: "",
      },
      boxing: {
        redirectUrl: "/betby",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/VirtualSlider/boxing.jpg",
        alt: "",
        name: "boxing",
        providerName: "boxing",
        gameType: "",
      },
      tabletennis: {
        redirectUrl: "/betby",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/VirtualSlider/tabletennis.jpg",
        alt: "",
        name: "Paragmatic",
        providerName: "Paragmatic",
        gameType: "",
      },
      Icehockey: {
        redirectUrl: "/betby",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/VirtualSlider/Icehockey.jpg",
        alt: "",
        name: "Icehockey",
        providerName: "Icehockey",
        gameType: "",
      },
    },

    imgUrl: "./images/icons/casino/VirtualSports.png",
    name: "Virtual Sports",
  },

  TvShows: {
    slide1: {
      CrazyTime: {
        redirectUrl: "/casino/evolution/CrazyTime",
        code: "1000148",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/tvshows/casino/crazytime.png",
        alt: "",
        name: "Crazy Time",
        providerName: "Evolution",
        gameType: "crazytime",
      },
      DreamCatcher: {
        redirectUrl: "/casino/evolution/DreamCatcher",
        code: "1000077",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/tvshows/casino/Dreamcatcher.jpg",
        alt: "",
        name: "Dream Catcher",
        providerName: "evolution",
        gameType: "moneywheel",
      },
      FootballStudio: {
        redirectUrl: "/casino/evolution/FootballStudio",
        code: "1000112",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/tvshows/casino/footballstudio.jpg",
        alt: "",
        name: "Football Studio",
        providerName: "Evolution",
        gameType: "topcard",
      },
      Megaball: {
        redirectUrl: "/casino/evolution/Megaball",
        code: "1000141",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/tvshows/casino/Megaball.jpg",
        alt: "",
        name: "Megaball",
        providerName: "Evolution",
        gameType: "megaball",
      },
      CashorCrash: {
        redirectUrl: "/casino/evolution/CashorCrash",
        code: "1000352",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/tvshows/casino/cashorcrash.jpg",
        alt: "",
        name: "Cash or Crash",
        providerName: "Evolution",
        gameType: "cashorcrash",
      },
      OTTAndarBahar: {
        redirectUrl: "/casino/ezg-casino-marina-andar-bahar",
        code: "328000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/tvshows/casino/ottandarbaharezugi.jpg",
        alt: "",
        name: "OTT Andar Bahar",
        providerName: "Ezugi",
        gameType: "andarbahar",
      },
      SicBo: {
        redirectUrl: "/casino/ezg-sic-bo",
        code: "224000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/tvshows/casino/sicbo.jpg",
        alt: "",
        name: "SicBo",
        providerName: "Ezugi",
        gameType: "sicbo",
      },
      UltimateSicBo: {
        redirectUrl: "/casino/ezg-ultimate-sic-bo",
        code: "224100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/tvshows/casino/ultimatesicbo.jpg",
        alt: "",
        name: "Ultimate SicBo",
        providerName: "Ezugi",
        gameType: "sicbo",
      },
    },

    imgUrl: "./images/icons/casino/TvGame.png",
    name: "TV Games",
  },

  Poker: {
    slide1: {
      HandCasinoHoldem2: {
        redirectUrl: "/casino/ezgevo-2-hand-casino-holdem",
        code: "1000073",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/poker/casino/2HandCasinoHoldem.jpg",
        alt: "",
        name: "2 Hand Casino Holdem",
        providerName: "Evolution",
        gameType: "poker",
      },
      BetOnTeenPatti: {
        redirectUrl: "/casino/ezg-bet-on-teen-patti",
        code: "227101",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/poker/casino/BetOnTeenPatti.jpg",
        alt: "",
        name: "Bet On Teen Patti",
        providerName: "Ezugi",
        gameType: "poker",
      },
      CasinoHoldem: {
        redirectUrl: "/casino/ezg-casino-holdem",
        code: "507000",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/poker/casino/CasinoHoldem.jpg",
        alt: "",
        name: "Casino Holdem",
        providerName: "Ezugi",
        gameType: "poker",
      },
      // OneDayTeenPatti: {
      //   redirectUrl: "/casino/ezg-one-day-teen-patti",
      //   code: "227102",
      //   casino: "ezugi",
      //   provider: "",
      //   homeUrl: pageUrlGenerator("/casinogame"),
      //   imgUrl: "./images/poker/casino/OneDayTeenPatti.jpg",
      //   alt: "",
      //   name: "One Day Teen Patti",
      //   providerName: "Ezugi",
      //   gameType: "poker",
      // },
      OneDayTeenPattiClassic: {
        redirectUrl: "/casino/ezg-one-day-teen-patti",
        code: "227103",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/poker/casino/OneDayTeenPattiClassic.jpg",
        alt: "",
        name: "One Day Teen Patti",
        providerName: "Ezugi",
        gameType: "poker",
      },
      Teenpatti3card: {
        redirectUrl: "/casino/ezg-teen-patti-3-card",
        code: "227100",
        casino: "ezugi",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/poker/casino/Teenpatti3card.jpg",
        alt: "",
        name: "Teen Patti 3 Card",
        providerName: "Ezugi",
        gameType: "poker",
      },
      TexasHoldemBonus: {
        redirectUrl: "/casino/ezgevo-texas-holdem-bonus-poker",
        code: "1000111",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/poker/casino/TexasHoldemBonus.jpg",
        alt: "",
        name: "Texas Holdem Bonus",
        providerName: "Evolution",
        gameType: "poker",
      },
      UltimateTexasHoldem: {
        redirectUrl: "/casino/ezgevo-ultimate-texas-holdem",
        code: "1000151",
        casino: "evolution",
        provider: "",
        homeUrl: pageUrlGenerator("/casinogame"),
        imgUrl: "./images/poker/casino/UltimateTexasHoldem.jpg",
        alt: "",
        name: "Ultimate Texas Holdem",
        providerName: "Evolution",
        gameType: "poker",
      },
    },

    imgUrl: "./images/icons/casino/Poker.png",
    name: "Poker",
  },
};
