import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { Accordion, Dropdown, Tab, Tabs } from "react-bootstrap";

import MenuIcon from "./../../assets/images/icons/sidebaricon/collapse.png";
import livecasino from "./../../assets/images/icons/sidebaricon/livecasino.png";
import poker from "./../../assets/images/icons/casino/Poker.png";
import promotion from "./../../assets/images/icons/sidebaricon/promotion.png";
import contactUs from "./../../assets/images/icons/sidebaricon/support.png";
import tvgame from "./../../assets/images/icons/sidebaricon/television.png";
import casinogame from "./../../assets/images/icons/sidebaricon/crown.png";
import favourite from "./../../assets/images/icons/sidebaricon/star.png";
import recent from "./../../assets/images/icons/sidebaricon/recent.png";
import topgames from "./../../assets/images/icons/sidebaricon/categories.png";
import providersicon from "./../../assets/images/icons/sidebaricon/responsible.png";
import virtualgame from "./../../assets/images/icons/virtualsports.png";

import EzugiIcon from "./../../assets/images/icons/sidebaricon/Ezugi.png";
import EvolutionIcon from "./../../assets/images/icons/sidebaricon/Evolution.png";
import VivoIcon from "./../../assets/images/icons/sidebaricon/VivoGaming.png";
import SupernowaIcon from "./../../assets/images/icons/sidebaricon/supernowa-logo.gif";
import PragamaticIcon from "./../../assets/images/icons/sidebaricon/ParagmatiPlay.png";
import onetouchIcon from "./../../assets/images/icons/sidebaricon/OneTouch.png";

import CasinoTabsIcon from "../../assets/images/icons/sidebaricon/blackjack.png";
import sportsTabsIcon from "../../assets/images/icons/sidebaricon/football.png";

import { APP_CONST } from "../../config/const";
import LoginModal from "../../views/components/authModals/LoginModal";
import MobileNumberModal from "../../views/components/authModals/MobileNumberModal";
import MobileNumberVerifyModal from "../../views/components/authModals/MobileNumberVerifyModal";
import RegisterModal from "../../views/components/authModals/RegisterModal";

const BeforeLeftBar = () => {
  const navigate = useNavigate();
  const persistValue = localStorage.getItem("isSideBarOpen")
    ? localStorage.getItem("isSideBarOpen") === "true"
    : false;

  const [showSideBar, setShowSidebar] = useState(persistValue);

  useEffect(() => {
    if (showSideBar) {
      document.body.classList.add("sidebar_open");
    } else {
      document.body.classList.remove("sidebar_open");
    }
  }, [showSideBar]);

  const handleRedirection = (redirectionUrl, tabName) => {
    if (redirectionUrl) {
      localStorage.setItem("isSideBarOpen", true);
      if (tabName) {
        navigate(redirectionUrl, {
          state: {
            tabName: tabName ? tabName : "",
          },
        });
      } else {
        navigate(redirectionUrl);
      }
    }
  };

  const [showAuthModals, setShowAuthModals] = useState({
    login: false,
    register: false,
    mobileNumber: false,
    verifyNumber: false,
  });

  const handleModalShow = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: true }));
  };

  const handleModalClose = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: false }));
  };
  const { appDetails } = useSelector((state) => state.app);
  const { isAuth } = useSelector((state) => state.auth);

  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const headerPages = ["casinogame", "betby"];
  useEffect(() => {
    const matchedValue = headerPages.find((f) =>
      location?.pathname.includes(f)
    );
    if (matchedValue) {
      setActivePage(matchedValue);
    }
  }, [location]);

  return (
    <>
      <div
        className={
          showSideBar ? "show beforeleftsidebar" : " beforeleftsidebar"
        }
      >
        <ul className="show-beforesidebar">
          <div className="casino-sports-tabs">
            <Button
              variant=""
              className="before-sidebar-menu"
              onClick={() => {
                localStorage.setItem("isSideBarOpen", !showSideBar);
                setShowSidebar(!showSideBar);
              }}
            >
              <img src={MenuIcon} />
            </Button>
            <div className="casino-sportsheader d-md-none">
              <ul>
                <li
                  className={activePage == "casinogame" ? "active" : ""}
                  onClick={() =>
                    handleRedirection("/casinogame", "Live Casino")
                  }
                >
                  <img src={CasinoTabsIcon} />
                  <span>Casino</span>
                </li>
                <li
                  className={activePage == "betby" ? "active" : ""}
                  onClick={() => {
                    !isAuth
                      ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                      : (window.location.href = "/betby");
                  }}
                >
                  <img src={sportsTabsIcon} />
                  <span>Sports</span>
                </li>
              </ul>
            </div>

            <Tabs
              defaultActiveKey="casino"
              id="uncontrolled-tab-example"
              className=""
            >
              <Tab
                eventKey="casino"
                // title={
                //   <span>
                //     <img src={CasinoTabsIcon} />
                //     Casino
                //   </span>
                // }
              >
                <ul>
                  <li
                    className="liveCasino"
                    onClick={() =>
                      handleRedirection("/casinogame", "Live Casino")
                    }
                  >
                    <a>
                      <img src={livecasino} />
                      <span className="game-name">Live Casino</span>
                    </a>
                    {/* <Button
                      variant=""
                      className="before-sidebar-menu"
                      onClick={() => {
                        localStorage.setItem("isSideBarOpen", !showSideBar);
                        setShowSidebar(!showSideBar);
                      }}
                    >
                      <img src={MenuIcon} />
                    </Button> */}
                  </li>
                  <li
                    onClick={() => handleRedirection("/casinogame", "TV Games")}
                  >
                    <a>
                      <img src={tvgame} />
                      <span className="game-name">TV Game</span>
                    </a>
                  </li>
                  <li
                    onClick={() =>
                      handleRedirection("/casinogame", "Casino Games")
                    }
                  >
                    <a>
                      <img src={casinogame} />
                      <span className="game-name"> Casino Game</span>
                    </a>
                  </li>
                  <li
                    onClick={() =>
                      handleRedirection("/casinogame", "Virtual Sports")
                    }
                  >
                    <a>
                      <img src={virtualgame} />
                      <span className="game-name"> Virtual Sports</span>
                    </a>
                  </li>
                  <li onClick={() => handleRedirection("/casinogame", "Poker")}>
                    <a>
                      <img src={poker} />
                      <span className="game-name">Poker</span>
                    </a>
                  </li>
                </ul>

                <ul>
                  <li>
                    <a href="javascript:void(0)">
                      <img src={favourite} />
                      <span className="game-name">Promotion</span>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">
                      <img src={favourite} />
                      <span className="game-name">My Favourite Games</span>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">
                      <img src={recent} />
                      <span className="game-name">Recent Games</span>
                    </a>
                  </li>
                </ul>

                <Accordion className="sidebaraccordian">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Top Live Casino</Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <li>
                          {/* <a onClick={() => handleRedirection("/casino/ezugi")}> */}
                          <a
                            onClick={() => {
                              !isAuth
                                ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                                : (window.location.href = "/casino/ezugi");
                            }}
                          >
                            <img src={EzugiIcon} />
                            <span className="game-name">Ezugi</span>
                          </a>
                        </li>
                        <li>
                          {/* <a onClick={() => handleRedirection("/casino/evolution")} */}
                          <a
                            onClick={() => {
                              !isAuth
                                ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                                : (window.location.href = "/casino/evolution");
                            }}
                          >
                            <img src={EvolutionIcon} />
                            <span className="game-name"> Evolution</span>
                          </a>
                        </li>
                        <li>
                          {/* <a onClick={() => handleRedirection("/casino/vivo")}> */}
                          <a
                            onClick={() => {
                              !isAuth
                                ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                                : (window.location.href = "/casino/vivo");
                            }}
                          >
                            <img src={VivoIcon} />
                            <span className="game-name">Vivo</span>
                          </a>
                        </li>
                        <li>
                          {/* <a onClick={() => handleRedirection("/casino/pragmaticplay")} */}
                          <a
                            onClick={() => {
                              !isAuth
                                ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                                : (window.location.href =
                                    "/casino/pragmaticplay");
                            }}
                          >
                            <img src={PragamaticIcon} />
                            <span className="game-name">Pragamatic </span>
                          </a>
                        </li>
                        <li className="d-none">
                          {/* <a onClick={() => handleRedirection("/casino/onetouch")}> */}
                          <a
                            onClick={() => {
                              !isAuth
                                ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                                : (window.location.href = "/casino/onetouch");
                            }}
                          >
                            <img src={onetouchIcon} />
                            <span className="game-name">One Touch </span>
                          </a>
                        </li>
                        <li>
                          {/* <a onClick={() => handleRedirection("/casino/supernowa")}> */}
                          <a
                            onClick={() => {
                              !isAuth
                                ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                                : (window.location.href = "/casino/supernowa");
                            }}
                          >
                            <img src={SupernowaIcon} />
                            <span className="game-name">Supernowa</span>
                          </a>
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <Accordion className="sidebaraccordian">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Top Categories</Accordion.Header>
                    <Accordion.Body>
                      <ul>
                        <li>
                          {/* <a onClick={() => handleRedirection("/slots")}> */}
                          <a
                            onClick={() => {
                              !isAuth
                                ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                                : (window.location.href = "/slots");
                            }}
                          >
                            <img src={EzugiIcon} />
                            <span className="game-name">Slots</span>
                          </a>
                        </li>
                        <li>
                          {/* <a onClick={() => handleRedirection("/casino/evolution")}> */}
                          <a
                            // onClick={() => {
                            //   !isAuth
                            //     ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                            //     : (window.location.href = "/casino/evolution");
                            // }}
                            onClick={() =>
                              handleRedirection("/slots", "Netent")
                            }
                          >
                            <img src={EvolutionIcon} />
                            <span className="game-name"> Netend</span>
                          </a>
                        </li>
                        <li>
                          {/* <a onClick={() => handleRedirection("/casino/vivo")}> */}
                          <a
                            // onClick={() => {
                            //   !isAuth
                            //     ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                            //     : (window.location.href = "/casino/vivo");
                            // }}
                            onClick={() =>
                              handleRedirection("/slots", "Red Tiger")
                            }
                          >
                            <img src={VivoIcon} />
                            <span className="game-name">Red Tiger</span>
                          </a>
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <ul>
                  {/* <li>
                    <a href="#">
                      <img src={promotion} />
                      <span className="game-name">Promotion</span>
                    </a>
                  </li> */}
                  <li className="support">
                    <a href={`tel://${appDetails?.PHONE}`}>
                      <img src={contactUs} />
                      <span className="game-name">Support</span>
                    </a>
                  </li>
                </ul>
              </Tab>

              <Tab
                eventKey="sports"
                // title={
                //   <span>
                //     <img src={sportsTabsIcon} />
                //     Sports
                //   </span>
                // }
              >
                <ul>
                  <li>
                    <a href="javascript:void(0)">
                      <img src={promotion} />
                      <span className="game-name">Promotion</span>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)">
                      <img src={contactUs} />
                      <span className="game-name">Contact Us</span>
                    </a>
                  </li>
                </ul>
              </Tab>
            </Tabs>
          </div>
        </ul>
      </div>

      <LoginModal
        show={showAuthModals.login}
        handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.LOGIN)}
        handleModalShow={handleModalShow}
      />
      <MobileNumberModal
        show={showAuthModals.mobileNumber}
        handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.MOBILE_NUMBER)}
        handleModalShow={handleModalShow}
      />
      <MobileNumberVerifyModal
        show={showAuthModals.verifyNumber}
        handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.VERIFY_NUMBER)}
        handleModalShow={handleModalShow}
      />
      <RegisterModal
        show={showAuthModals.register}
        handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.REGISTER)}
        handleModalShow={handleModalShow}
      />
    </>
  );
};

export default BeforeLeftBar;
//
