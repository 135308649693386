import React, { useState } from "react";
import Slider from "react-slick";
import ImageCard from "./imageCard";
import favourite from "../../assets/images/fav.png";
import HomeTabsSection from "./HomeTabsSection";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { APP_CONST } from "../../config/const";
import LoginModal from "../../views/components/authModals/LoginModal";
import MobileNumberModal from "../../views/components/authModals/MobileNumberModal";
import MobileNumberVerifyModal from "../../views/components/authModals/MobileNumberVerifyModal";
import RegisterModal from "../../views/components/authModals/RegisterModal";

import PlayBtn from "../../assets/images/icons/play.png";

const SliderSection = ({
  isSearched,
  activeCategory,
  data,
  sliderPageNumber,
  isAuth,
  showSlider,
  filteredData,
  setSliderPageNumber,
  setShowSlider,
}) => {
  const navigate = useNavigate();
  const settings = {
    dots: false,
    arrows: true,
    autoplay: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 7,
    slidesToScroll: 6,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 7,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 7,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 7,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style }}
        onClick={(e) => {
          window.onclick = (ev) => {
            // ev.target.parentNode.parentNode.id;
            handleSlideCounter("inc", ev.target.parentNode.parentNode.id);
          };

          onClick(e);
        }}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style }}
        onClick={(e) => {
          window.onclick = (ev) => {
            // ev.target.parentNode.parentNode.id;
            handleSlideCounter("dec", ev.target.parentNode.parentNode.id);
          };
          onClick(e);
        }}
      />
    );
  }
  const getShowSlider = (name) => {
    return showSlider?.showMultipleSlides?.[name] && activeCategory === "Lobby";
  };
  const handleSlideCounter = (type = "inc", cat) => {
    let filteredItem = Object.values(data)?.find((f) => f.name === cat);

    if (filteredItem) {
      const slideLength = filteredItem && Object.keys(filteredItem)?.length - 1;
      if (type == "inc") {
        if (slideLength === sliderPageNumber[cat]) {
          let obj = sliderPageNumber;
          obj[cat] = 1;
          setSliderPageNumber({ ...obj });
        } else {
          let obj = sliderPageNumber;
          obj[cat] = obj[cat] + 1;
          setSliderPageNumber({ ...obj });
        }
      } else {
        if (sliderPageNumber[cat] === 1) {
          let obj = sliderPageNumber;
          obj[cat] = slideLength;
          setSliderPageNumber({ ...obj });
        } else {
          let obj = sliderPageNumber;
          obj[cat] = obj[cat] - 1;
          setSliderPageNumber({ ...obj });
        }
      }
    }
  };

  const handleShowSliderClick = (name) => {
    let obj = showSlider.showMultipleSlides;
    Object.keys(obj).forEach((key) => {
      if (key !== name) {
        obj[key] = true;
      }
    });
    obj[name] = !obj?.[name];
    setShowSlider((p) => ({
      ...p,
      showMultipleSlides: obj,
    }));
  };

  const { user } = useSelector((state) => state.auth);
  // const navigate = useNavigate();
  const [showAuthModals, setShowAuthModals] = useState({
    login: false,
    register: false,
    mobileNumber: false,
    verifyNumber: false,
  });

  const handleModalShow = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: true }));
  };

  const handleModalClose = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: false }));
  };
  const { appDetails } = useSelector((state) => state.app);
  // const { isAuth } = useSelector((state) => state.auth);

  return (
    <div className="slots-section">
      <div className="games-section casino_games">
        {!isSearched ? (
          Object.values(data)
            .filter((f) => {
              if (f?.name === activeCategory || activeCategory === "Lobby") {
                return true;
              }
            })
            .map((value) => {
              const slideLength = Object.values(value).filter(
                (f) => typeof f !== "string"
              ).length;
              return (
                <>
                  <div id={value.name} className={value.class}>
                    <div className="game-heading casino-game-heading ">
                      <span>
                        {<img src={value?.imgUrl} />}
                        {value?.["name"]}
                        <span className="game-count">160</span>
                      </span>
                      {/* {slideLength > 1 && (
                        <p
                          className={
                            getShowSlider(value?.name) ? "pagination" : "d-none"
                          }
                        >
                          {sliderPageNumber[value.name]}/{slideLength}
                        </p>
                      )} */}
                      {/* {activeCategory == "Lobby" && (
                        <button
                          className="games-see-all"
                          type="button"
                          onClick={() => {
                            handleShowSliderClick(value?.name);
                          }}
                        >
                          {getShowSlider(value?.name)
                            ? "Show More"
                            : "Show Less"}
                        </button>
                      )} */}
                    </div>
                    {getShowSlider(value?.name) ? (
                      <Slider {...settings}>
                        {Object.values(value)
                          .filter((f) => typeof f !== "string")
                          .map((slide) => {
                            // console.log(slide);
                            return Object.values(slide).map((s) => {
                              return (
                                <>
                                  <div className="game-box">
                                    <div className="img-box">
                                      <div className="overlay">
                                        <a
                                          onClick={() => {
                                            !isAuth
                                              ? handleModalShow(
                                                  APP_CONST.AUTH_MODAL.LOGIN
                                                )
                                              : (window.location.href =
                                                  s.redirectUrl);
                                          }}
                                          className="play-btn"
                                        >
                                          <img src={PlayBtn} />
                                        </a>
                                      </div>
                                      <img src={s.imgUrl} alt="{slide.alt}" />
                                    </div>
                                    <div className="game_name">{s.name}</div>
                                    {/* <div className="game_name">
                                      {s.providerName}
                                    </div> */}
                                  </div>
                                </>
                              );
                            });
                          })}
                      </Slider>
                    ) : (
                      <div>
                        <ul>
                          <li>
                            {Object.values(value)
                              .filter((f) => typeof f !== "string")
                              .map((item) => {
                                return Object.values(item).map((s) => {
                                  // console.log("v", v);
                                  return (
                                    <>
                                      <div className="game-box">
                                        <div className="img-box">
                                          <div className="overlay">
                                            <a
                                              onClick={() => {
                                                !isAuth
                                                  ? handleModalShow(
                                                      APP_CONST.AUTH_MODAL.LOGIN
                                                    )
                                                  : (window.location.href =
                                                      s.redirectUrl);
                                              }}
                                              className="play-btn"
                                            >
                                              <img src={PlayBtn} />
                                            </a>
                                          </div>
                                          <img
                                            src={s.imgUrl}
                                            alt="{slide.alt}"
                                          />
                                          <div className="game_name">
                                            {s.name}
                                          </div>
                                        </div>
                                        {/* <div className="game_name">
                                          {s.providerName}
                                        </div> */}
                                      </div>
                                    </>
                                  );
                                });
                              })}
                          </li>
                        </ul>
                        <div className="showMoreCasino">
                          <button
                            className="btn btn-primary"
                            onClick={() =>
                              navigate(
                                `/casinogame/${activeCategory.replace(
                                  " ",
                                  "-"
                                )}`
                              )
                            }
                          >
                            Show More
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              );
            })
        ) : (
          <div className="slotsGameSearch">
            {filteredData.length &&
              filteredData.map((s, index) => {
                return (
                  <div className="game-box" key={index}>
                    <div className="img-box">
                      <div className="overlay">
                        <a
                          onClick={() => {
                            !isAuth
                              ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                              : (window.location.href = s.redirectUrl);
                          }}
                          className="play-btn"
                        >
                          <img src={PlayBtn} />
                        </a>
                      </div>
                      <img src={s.imgUrl} alt="{slide.alt}" />
                    </div>
                    <div className="game_name">{s.name}</div>
                    {/* <div className="game_name">
              {s.providerName}
            </div> */}
                  </div>
                );
              })}
          </div>
        )}
      </div>

      <LoginModal
        show={showAuthModals.login}
        handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.LOGIN)}
        handleModalShow={handleModalShow}
      />
      <MobileNumberModal
        show={showAuthModals.mobileNumber}
        handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.MOBILE_NUMBER)}
        handleModalShow={handleModalShow}
      />
      <MobileNumberVerifyModal
        show={showAuthModals.verifyNumber}
        handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.VERIFY_NUMBER)}
        handleModalShow={handleModalShow}
      />
      <RegisterModal
        show={showAuthModals.register}
        handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.REGISTER)}
        handleModalShow={handleModalShow}
      />
    </div>
  );
};
export default SliderSection;
