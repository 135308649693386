import Slide1 from "../../../assets/images/homeSliders/NewGames/new/CashOrCash1@4x@x28.jpg";
import Slide2 from "../../../assets/images/homeSliders/NewGames/new/Craps4@4x@x2.jpg";
import Slide3 from "../../../assets/images/homeSliders/NewGames/new/CrazyTime@5x@x2.jpg";
import Slide4 from "../../../assets/images/homeSliders/NewGames/new/CricketWarSL4@4x@x2.jpg";
import Slide6 from "../../../assets/images/homeSliders/NewGames/new/DoubleBallRoulette@4x@x2.jpg";
import Slide7 from "../../../assets/images/homeSliders/NewGames/new/FirstPersonAmericanRoulette1@513x767@x2.jpg";
import Slide9 from "../../../assets/images/homeSliders/NewGames/new/FortuneBaccarat@4x@x2.jpg";
import Slide10 from "../../../assets/images/homeSliders/NewGames/new/GonzosTreasureHunt4@4x@x2.jpg";
import Slide11 from "../../../assets/images/homeSliders/NewGames/new/InstantRoulette1@3x.jpg";
import Slide12 from "../../../assets/images/homeSliders/NewGames/new/LightningBlackjack@4x-1@x2.jpg";
import Slide13 from "../../../assets/images/homeSliders/NewGames/new/OneDayTeenPatti1@4x@x2.jpg";
import Slide14 from "../../../assets/images/homeSliders/NewGames/new/PowerBJ@3x-min.jpg";
import Slide15 from "../../../assets/images/homeSliders/NewGames/new/SalonPriveBlackjackSL3@4x@x2.jpg";
import Slide16 from "../../../assets/images/homeSliders/NewGames/new/SicBoEzugiSL1@4x@x2.jpg";
import Slide17 from "../../../assets/images/homeSliders/NewGames/new/SpeedRoulette1@4x@x2.jpg";
import Slide20 from "../../../assets/images/homeSliders/NewGames/new/TexasHoldemBonus@4x@x2.jpg";
import Slide21 from "../../../assets/images/homeSliders/NewGames/new/TripleCardPoker@4x@x2.jpg";
import Slide23 from "../../../assets/images/homeSliders/NewGames/new/UltimateSicBo@4x@x2.jpg";
import Slide24 from "../../../assets/images/homeSliders/NewGames/new/VIPFortuneBaccarat@4x@x2.jpg";

const NewGamesData = [
  {
    a: "PLAY",
    link: "/casino/ezgevo-cash-or-crash",
    image: Slide1,
    alt: "casino game",
    gameName: "Cash Or Cash",
  },
  {
    a: "PLAY",
    link: "/casino/evolution/Craps",
    image: Slide2,
    alt: "casino game",
    gameName: "Craps",
  },
  {
    a: "PLAY",
    link: "/casino/evolution/CrazyTime",
    image: Slide3,
    alt: "casino game",
    gameName: "Crazy Time",
  },
  {
    a: "PLAY",
    link: "/casino/ezugi/CricketWar",
    image: Slide4,
    alt: "casino game",
    gameName: "Cricket War ",
  },
  // {
  //   a: "PLAY",
  //   link: "javascript:void(0)",
  //   image: Slide5,
  //   alt: "casino game",
  //   gameName: "Dead Or Alive Saloon",
  // },
  {
    a: "PLAY",
    link: "/casino/evolution/DoubleBallRoulette",
    image: Slide6,
    alt: "casino game",
    gameName: "Double Ball Roulette",
  },
  {
    a: "PLAY",
    link: "/casino/evolution/FirstPersonAmericanRoulette",
    image: Slide7,
    alt: "casino game",
    gameName: "First Person American Roulette",
  },
  // {
  //   a: "PLAY",
  //   link: "javascript:void(0)",
  //   image: Slide8,
  //   alt: "casino game",
  //   gameName: "Football Studio Roulette",
  // },
  {
    a: "PLAY",
    link: "/casino/ezg-fortune-baccarat",
    image: Slide9,
    alt: "casino game",
    gameName: "Fortune Baccarat",
  },
  // {
  //   a: "PLAY",
  //   link: "/casino/ezgevo-gonzos-treasure-hunt",
  //   image: Slide10,
  //   alt: "casino game",
  //   gameName: "Gonzos Treasure Hunt",
  // },
  {
    a: "PLAY",
    link: "/casino/ezgevo-instant-roulette",
    image: Slide11,
    alt: "casino game",
    gameName: "Instant Roulette",
  },
  {
    a: "PLAY",
    link: "/casino/ezgevo-first-person-lightning-blackjack",
    image: Slide12,
    alt: "casino game",
    gameName: "Lightning Blackjack",
  },
  {
    a: "PLAY",
    link: "/casino/ezg-one-day-teen-patti",
    image: Slide13,
    alt: "casino game",
    gameName: "OneDay TeenPatti",
  },
  {
    a: "PLAY",
    link: "/casino/evolution/PowerBlackjack",
    image: Slide14,
    alt: "casino game",
    gameName: "Power BJ",
  },
  {
    a: "PLAY",
    link: "/casino/ezgevo-salon-priv-baccarat-b",
    image: Slide15,
    alt: "casino game",
    gameName: "Salon Prive Blackjack SL",
  },
  {
    a: "PLAY",
    link: "/casino/ezgevo-super-sic-bo",
    image: Slide16,
    alt: "casino game",
    gameName: "SicBo Ezugi SL",
  },
  {
    a: "PLAY",
    link: "/casino/ezgevo-speed-roulette",
    image: Slide17,
    alt: "casino game",
    gameName: "Speed Roulette",
  },
  // {
  //   a: "PLAY",
  //   link: "javascript:void(0)",
  //   image: Slide18,
  //   alt: "casino game",
  //   gameName: "Super Andar Bahar",
  // },
  // {
  //   a: "PLAY",
  //   link: "javascript:void(0)",
  //   image: Slide19,
  //   alt: "casino game",
  //   gameName: "TeenPatti",
  // },
  {
    a: "PLAY",
    link: "/casino/evolution/Texasholdem",
    image: Slide20,
    alt: "casino game",
    gameName: "Texas Holdem Bonus",
  },
  {
    a: "PLAY",
    link: "/casino/ezgevo-three-card-poker",
    image: Slide21,
    alt: "casino game",
    gameName: "Three Card Poker",
  },
  // {
  //   a: "PLAY",
  //   link: "javascript:void(0)",
  //   image: Slide22,
  //   alt: "casino game",
  //   gameName: "Ultimate Roulette",
  // },
  {
    a: "PLAY",
    link: "/casino/ezg-ultimate-sic-bo",
    image: Slide23,
    alt: "casino game",
    gameName: "Ultimate SicBo",
  },
  {
    a: "PLAY",
    link: "/casino/ezg-vip-fortune-baccarat",
    image: Slide24,
    alt: "casino game",
    gameName: "VIP Fortune Baccarat",
  },
  // ... more slide objects
];

export default NewGamesData;
