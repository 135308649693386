import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import parsePhoneNumber from "libphonenumber-js";
import { sendOtpToMobile } from "../../../redux/auth/actions";
import { useDispatch } from "react-redux";
import { Button, Form } from "react-bootstrap";
import { APP_CONST } from "../../../config/const";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { Toaster } from "../../../utils/toaster";
import PhoneInput from "react-phone-input-2";

import FacebookModal from "../../../assets/images/icons/socialiocns/facebook.png";
import TelegramModal from "../../../assets/images/icons/socialiocns/telegram.png";
import InstagramModal from "../../../assets/images/icons/socialiocns/instagram.png";
import WhatsappModal from "../../../assets/images/icons/socialiocns/whatsapp.png";

const MobileNumberModal = ({ show, handleClose, handleModalShow }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const OTP = useSelector((state) => state?.app?.appDetails);
  const [searchParams, setSearchParams] = useSearchParams();
  const [referalCode, setReferalCode] = useState(searchParams.get("refferal"));
  const [agentCode, setAgentCode] = useState(searchParams.get("agent_code"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmit = () => {
    setIsSubmitting(true);
    const phoneNumber = parsePhoneNumber("+" + mobileNumber);
    let payload = {};
    if (phoneNumber) {
      payload.mobile = phoneNumber.nationalNumber;
      payload.country_code = phoneNumber.countryCallingCode;
    }

    dispatch(sendOtpToMobile(payload))
      .then((res) => {
        setIsSubmitting(false);
        if (res?.data?.error === false) {
          persistDetails(payload);

          if (OTP && OTP?.OTP === 0) {
            // navigate("/verify-number", {
            //   state: { referalCode, agentCode },
            // });
            handleClose();
            handleModalShow(APP_CONST.AUTH_MODAL.VERIFY_NUMBER);
          } else {
            // navigate("/register", {
            //   state: { referalCode, agentCode },
            // });
            handleClose();
            handleModalShow(APP_CONST.AUTH_MODAL.REGISTER);
          }
        } else {
          Toaster(APP_CONST.TOAST_TYPE.ERROR, res?.data?.message);
        }
      })
      .catch((err) => {
        Toaster(APP_CONST.TOAST_TYPE.ERROR, err?.response?.data?.message);
        setIsSubmitting(false);
      });
  };

  const persistDetails = (data) => {
    localStorage.setItem("mobile_details", JSON.stringify(data));
  };
  return (
    <Modal
      show={show}
      className={"steps-canvas login-modal LoginImg SignUpImg"}
      onHide={() => {
        console.log("working");
      }}
      placement={"end"}
    >
      <Modal.Header closeButton onClick={handleClose} closeVariant="white">
        {/* <Modal.Title>Sign in now!</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <div className="login-section">
          {/* <div className="login-leftPart">
            <img src={LoginLeftPart} />
          </div> */}
          <div className="Login-rightPart">
            <Modal.Title>Verify Number</Modal.Title>
            <div className="form-steps">
              <h2 className="h2">Welcome!</h2>
              <p>Please enter your 10 digit mobile number to get an OTP</p>
              <Form>
                <Form.Group
                  className="mb-3 position-relative"
                  controlId="formBasicPassword"
                >
                  <PhoneInput
                    country={"in"}
                    inputProps={{
                      name: "phone",
                      required: true,
                    }}
                    onlyCountries={["in", "np"]}
                    inputStyle={{
                      display: "block",
                      width: "100%",
                      lineHeight: 1.5,
                      color: "#fff",
                      backgroundColor: "#26324B",
                      backgroundClip: "padding-box",
                      border: "#26324B",
                      borderRadius: "10px",
                      padding: "0.7rem 0.75rem",
                      borderColor: "#26324B",
                      fontSize: "0.86rem",
                      minHeight: "41px",
                      paddingLeft: "50px",
                    }}
                    onChange={(phone) => {
                      // alert("phone", phone);
                      // alert(phone);
                      setMobileNumber(phone);
                    }}
                  />
                </Form.Group>
              </Form>
              <Button
                variant="primary"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Loading.." : "Next"}
              </Button>
              <div className="sign-up mt-4">
                Already a member?
                <br />
                <a
                  href="javascript:void(0)"
                  onClick={() => {
                    handleClose();
                    handleModalShow(APP_CONST.AUTH_MODAL.LOGIN);
                  }}
                >
                  Sign In here
                </a>
              </div>

              <div className="social-icon-modal">
                <ul>
                  <li>
                    <img src={FacebookModal} />
                  </li>
                  <li>
                    <img src={TelegramModal} />
                  </li>
                  <li>
                    <img src={InstagramModal} />
                  </li>
                  <li>
                    <img src={WhatsappModal} />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default MobileNumberModal;
