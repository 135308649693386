import Slide1 from "../../../assets/images/homeSliders/VirtualSlider/Cricket.jpg";
import Slide2 from "../../../assets/images/homeSliders/VirtualSlider/In-Play.jpg";
import Slide3 from "../../../assets/images/homeSliders/VirtualSlider/Soccer.jpg";
import Slide5 from "../../../assets/images/homeSliders/VirtualSlider/Tennis.jpg";
import Slide6 from "../../../assets/images/homeSliders/VirtualSlider/tabletennis.jpg";
import Slide7 from "../../../assets/images/homeSliders/VirtualSlider/basketball.jpg";
import Slide8 from "../../../assets/images/homeSliders/VirtualSlider/boxing.jpg";
import Slide9 from "../../../assets/images/homeSliders/VirtualSlider/Icehockey.jpg";
const VirtualData = [
  {
    a: "PLAY",
    link: "/betby",
    image: Slide1,
    alt: "casino game",
    gameName: "Diamond Roulette",
  },
  {
    a: "PLAY",
    link: "/betby",
    image: Slide2,
    alt: "casino game",
    gameName: "Auto Roulette",
  },
  {
    a: "PLAY",
    link: "/betby",
    image: Slide3,
    alt: "casino game",
    gameName: "Double Ball Roulette",
  },
  {
    a: "PLAY",
    link: "/betby",
    image: Slide5,
    alt: "casino game",
    gameName: "Instant Roulette1",
  },
  {
    a: "PLAY",
    link: "/betby",
    image: Slide6,
    alt: "casino game",
    gameName: "Oracle Casino",
  },
  {
    a: "PLAY",
    link: "/betby",
    image: Slide7,
    alt: "casino game",
    gameName: "Protomasso Roulette",
  },
  {
    a: "PLAY",
    link: "/betby",
    image: Slide8,
    alt: "casino game",
    gameName: "Namaste Roulette",
  },
  {
    a: "PLAY",
    link: "/betby",
    image: Slide9,
    alt: "casino game",
    gameName: "Speed Auto Roulette ",
  },

  // ... more slide objects
];

export default VirtualData;
