import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { slotsData } from "../../lib/data/slots.data";
import PlayBtn from "../../assets/images/icons/play.png";

const SearchModal = ({ show, setShow }) => {
  const [filteredData, setFilteredData] = useState([]);
  const handleSearch = (e) => {
    const value = e?.target?.value?.toLowerCase();

    if (value) {
      let filteredValues = [];

      Object.values(slotsData).forEach((slide) => {
        if (slide.name !== "All Games") {
          Object.values(slide).map((item, index) => {
            if (typeof item !== "string") {
              let response = Object.values(item).filter(
                (f) =>
                  f?.name
                    ?.toLowerCase()
                    .trim()
                    ?.indexOf(value.toLowerCase().trim()) > -1
              );
              let clone = [...filteredValues];
              clone = [...clone, ...response];
              filteredValues = clone;
            }
          });
        }
      });

      setFilteredData(filteredValues);
    }
  };

  return (
    <div>
      <Modal className="searchGamesModal" show={show}>
        <Modal.Header closeButton onClick={() => setShow(false)}>
          <Modal.Title>Search For Games</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control onChange={handleSearch} />
          <div className="PageContainer">
            <div className="slots-section">
              <div className="games-section casino_games">
                {filteredData.length &&
                  filteredData.map((s) => {
                    return (
                      <div className="game-box">
                        <div className="img-box">
                          <div className="overlay">
                            <a href={s.redirectUrl} className="play-btn">
                              <img src={PlayBtn} />
                            </a>
                          </div>
                          <img src={s.imgUrl} alt="{slide.alt}" />
                        </div>
                        <div className="game_name">{s.name}</div>
                        {/* <div className="game_name">{s.providerName}</div> */}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-none">
          <Button onClick={() => setShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default SearchModal;
