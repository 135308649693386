import EvolutionIcon from "../../../assets/images/providers/new/evolution.png";
import EzugiIcon from "../../../assets/images/providers/new/Ezugi.png";
import VivoIcon from "../../../assets/images/providers/new/vivo.png";
import SupernowaIcon from "../../../assets/images/providers/new/Supernowa.png";
import PragmaticIcon from "../../../assets/images/providers/new/pragmaticplay.png";
import OnlyPlayIcon from "../../../assets/images/providers/new/onlyplay.png";
import onetouchIcon from "../../../assets/images/providers/new/onetouch.png";
import qtechIcon from "../../../assets/images/providers/new/QTECH.png";
import spribeIcon from "../../../assets/images/providers/new/spribe.png";
import xpgIcon from "../../../assets/images/providers/new/XPG.png";

export const ProviderData = [
  {
    a: "PLAY",
    link: "/casino/evolution/",
    image: EvolutionIcon,
    alt: "evolution provider",
  },
  {
    a: "PLAY",
    link: "/casino/ezugi",
    image: EzugiIcon,
    alt: "ezugi provider",
  },

  {
    a: "PLAY",
    link: "/casino/vivo",
    image: VivoIcon,
    alt: "vivo provider",
  },
  {
    a: "PLAY",
    link: "/casino/supernowa",
    image: SupernowaIcon,
    alt: "supernowa provider",
  },
  {
    a: "PLAY",
    link: "/casino/pragmaticplay",
    image: PragmaticIcon,
    alt: "pragmaticplay provider",
  },
  {
    a: "PLAY",
    link: "/casino/onlyplay",
    image: OnlyPlayIcon,
    alt: "only play provider",
  },
  // {
  //   a: "PLAY",
  //   link: "/casino/onetouch",
  //   image: onetouchIcon,
  //   alt: "onetouch provider",
  // },
  {
    a: "PLAY",
    link: "/casino/qtech",
    image: qtechIcon,
    alt: "qtech provider",
  },
  {
    a: "PLAY",
    link: "/casino/xpg",
    image: xpgIcon,
    alt: "xpg provider",
  },
  {
    a: "PLAY",
    link: "/casino/spribe/aviator",
    image: spribeIcon,
    alt: "spribe provider",
  },
];
