import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Tab, Tabs } from "react-bootstrap";
import CasinoGames from "./CasinoGames";
import TopGamesData from "./homeSliders/TopGamesData";
import NewGamesData from "./homeSliders/NewGamesData";
import RouletteData from "./homeSliders/RouletteData";
import BlackJackData from "./homeSliders/BlackJackData";
import BaccaratData from "./homeSliders/BaccaratData";
import TvShowsData from "./homeSliders/TvShowsData";
import Holdem from "./homeSliders/Holdem";
import { ProviderData } from "./homeSliders/ProviderData";
import { HoldemData, lottery, sicboData } from "./homeSliders/HoldemData";
import SliderSection from "./sliderSection";
import { Slide } from "react-toastify";
import Slider from "react-slick";

import TopGamesIcon from "../../assets/images/icons/topgameicon.png";
import NewGamesIcon from "../../assets/images/icons/newgame.png";
import VirtualSports from "../../assets/images/icons/virtualsports.png";
import Recomment from "../../assets/images/icons/recommeny.png";
import RouletteIcon from "../../assets/images/icons/roulette.png";
import blackjackIcon from "../../assets/images/icons/baccarat.png";
import BaccaratIcon from "../../assets/images/icons/symbol-of-spades.png";
import TVIcon from "../../assets/images/icons/broadcast.png";
import providerIcon from "../../assets/images/icons/provider.png";
import bonusIcon from "../../assets/images/icons/bonus.png";
import VirtualData from "./homeSliders/VirtualData";
import BonusData from "./homeSliders/BonusData";
import RecommendedData from "./homeSliders/RecommendedData";

import PlayBtn from "../../assets/images/icons/play.png";

import { APP_CONST } from "../../config/const";
import LoginModal from "../../views/components/authModals/LoginModal";
import MobileNumberModal from "../../views/components/authModals/MobileNumberModal";
import MobileNumberVerifyModal from "../../views/components/authModals/MobileNumberVerifyModal";
import RegisterModal from "../../views/components/authModals/RegisterModal";

const HomeTabsSection = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [showAuthModals, setShowAuthModals] = useState({
    login: false,
    register: false,
    mobileNumber: false,
    verifyNumber: false,
  });

  const handleModalShow = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: true }));
  };

  const handleModalClose = (modalName) => {
    setShowAuthModals((p) => ({ ...p, [modalName]: false }));
  };
  const { appDetails } = useSelector((state) => state.app);
  const { isAuth } = useSelector((state) => state.auth);

  const settings = {
    dots: false,
    arrows: true,
    autoplay: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 7,
    slidesToScroll: 7,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 7,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const settings1 = {
    dots: false,
    arrows: true,
    autoplay: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const settings2 = {
    dots: false,
    arrows: true,
    autoplay: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  const [key, setKey] = useState("allGames");

  return (
    <div className="home_tabs_section casino_games">
      <div className="mb-5" id="topgames">
        <h4>
          <img src={TopGamesIcon} />
          Top Games
        </h4>
        <Slider {...settings}>
          {TopGamesData.map((slide) => (
            <div>
              <div className="game-box" key={slide.a}>
                <div className="img-box">
                  <div className="overlay">
                    <a
                      onClick={() => {
                        !isAuth
                          ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                          : (window.location.href = slide.link);
                      }}
                      className="play-btn"
                    >
                      <img src={PlayBtn} />
                    </a>
                  </div>
                  <img src={slide.image} alt="{slide.alt}" />
                </div>
                <div className="game_name">{slide.gameName}</div>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <div className="mb-5">
        <h4>
          <img src={NewGamesIcon} />
          New Games
        </h4>
        <Slider {...settings1}>
          {NewGamesData.map((slide) => (
            <div>
              <div className="game-box" key={slide.a}>
                <div className="img-box">
                  <div className="overlay">
                    <a
                      onClick={() => {
                        !isAuth
                          ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                          : (window.location.href = slide.link);
                      }}
                      className="play-btn"
                    >
                      <img src={PlayBtn} />
                    </a>
                  </div>
                  <img src={slide.image} alt="{slide.alt}" />
                </div>
                <div className="game_name">{slide.gameName}</div>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <div className="mb-5">
        <h4>
          <img src={VirtualSports} />
          Virtual Sports
        </h4>
        <Slider {...settings}>
          {VirtualData.map((slide) => (
            <div>
              <div className="game-box" key={slide.a}>
                <div className="img-box">
                  <div className="overlay">
                    <a
                      onClick={() => {
                        !isAuth
                          ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                          : (window.location.href = slide.link);
                      }}
                      className="play-btn"
                    >
                      <img src={PlayBtn} />
                    </a>
                  </div>
                  <img src={slide.image} alt="{slide.alt}" />
                </div>
                <div className="game_name">{slide.gameName}</div>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <div className="mb-5 providersection">
        <h4>
          <img src={providerIcon} />
          Provider
        </h4>
        <Slider {...settings1}>
          {ProviderData.map((slide) => (
            <div>
              <div className="providersection">
                <div className="game-box topProviders" key={slide.a}>
                  <div className="img-box">
                    <div className="overlay">
                      <a
                        onClick={() => {
                          !isAuth
                            ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                            : (window.location.href = slide.link);
                        }}
                        className="play-btn"
                      >
                        <img src={PlayBtn} />
                      </a>
                    </div>
                    <img src={slide.image} alt="{slide.alt}" />
                  </div>
                  <div className="game_name">{slide.gameName}</div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <div className="mb-5 bonussection">
        <h4>
          <img src={bonusIcon} />
          Bonuses
        </h4>
        <Slider {...settings2}>
          {BonusData.map((slide) => (
            <div>
              <div className="game-box" key={slide.a}>
                <div className="img-box">
                  <div className="overlay d-none">
                    {/* <a href={slide.link} className="play-btn"> */}
                    <a href="javascript:void(0):" className="play-btn">
                      <img src={PlayBtn} />
                    </a>
                  </div>
                  <img src={slide.image} alt="{slide.alt}" />
                </div>
                <div className="game_name">{slide.gameName}</div>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <div className="mb-5">
        <h4>
          <img src={Recomment} />
          Recommended
        </h4>
        <Slider {...settings}>
          {RecommendedData.map((slide) => (
            <div>
              <div className="game-box" key={slide.a}>
                <div className="img-box">
                  <div className="overlay">
                    <a
                      onClick={() => {
                        !isAuth
                          ? handleModalShow(APP_CONST.AUTH_MODAL.LOGIN)
                          : (window.location.href = slide.link);
                      }}
                      className="play-btn"
                    >
                      <img src={PlayBtn} />
                    </a>
                  </div>
                  <img src={slide.image} alt="{slide.alt}" />
                </div>
                <div className="game_name">{slide.gameName}</div>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <LoginModal
        show={showAuthModals.login}
        handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.LOGIN)}
        handleModalShow={handleModalShow}
      />
      <MobileNumberModal
        show={showAuthModals.mobileNumber}
        handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.MOBILE_NUMBER)}
        handleModalShow={handleModalShow}
      />
      <MobileNumberVerifyModal
        show={showAuthModals.verifyNumber}
        handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.VERIFY_NUMBER)}
        handleModalShow={handleModalShow}
      />
      <RegisterModal
        show={showAuthModals.register}
        handleClose={() => handleModalClose(APP_CONST.AUTH_MODAL.REGISTER)}
        handleModalShow={handleModalShow}
      />
    </div>
  );
};

export default HomeTabsSection;
